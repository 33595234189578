import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
  CoffeeOutlined,
  AppstoreOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  DesktopOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { KASIR_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "transaksi",
    path: `${KASIR_PREFIX_PATH}/transaksi`,
    title: "Transaksi",
    icon: DesktopOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "stok-harian",
    path: `${KASIR_PREFIX_PATH}/stok-harian`,
    title: "Stok Harian",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "laporan-penjualan",
    path: `${KASIR_PREFIX_PATH}/laporan-penjualan`,
    title: "Laporan Penjualan",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pengiriman-produk",
    path: `${KASIR_PREFIX_PATH}/pengiriman-produk`,
    title: "Pengiriman Produk",
    icon: InboxOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "delivery",
    path: `${KASIR_PREFIX_PATH}/delivery`,
    title: "Delivery",
    icon: CarOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "closing",
    path: "",
    title: localStorage.statusClosing,
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
