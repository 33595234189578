import { REKAP_TRANSAKSI } from "../../constants/admin/masterdata/constants";
import { deleteDb, handleGet, handlePost } from "redux/actions/baseAction";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";
import { checkLoading, getOffset, PAGINATION } from "helpers/general_helper";
import moment from "moment";

export const setLoading = (load) => ({
  type: REKAP_TRANSAKSI.LOADING_DATA_REKAP_TRANSAKSI_USER_SUCCESS,
  load,
});

export const setData = (data) => {
  return {
    type: REKAP_TRANSAKSI.GET_DATA_REKAP_TRANSAKSI_USER_SUCCESS,
    data,
  };
};
export const setLoadingDownload = (load) => ({
  type: REKAP_TRANSAKSI.LOADING_DOWNLOAD_DANA_POTONGAN,
  load,
});

export const setDataDownload = (data) => {
  return {
    type: REKAP_TRANSAKSI.DOWNLOAD_DANA_POTONGAN,
    data,
  };
};

export const getRekapTransaksiAction = (
  date = moment().format("YYYY-MM-DD"),
  limit = PAGINATION.limit,
  offset = PAGINATION.offset,
  query = "",
  // callback = null,
  isClear = true
) => {
  return async (dispatch) => {
    let url = `core/admin/transaction?offset=${getOffset(
      offset,limit
    )}&limit=${limit}&date=${date}`;
    if (query !== "") {
      url += `&query=${query}`;
    }
    let isLoading = true;
    if (isClear) {
      deleteDb(url);
    } else {
      isLoading = await checkLoading(url);
    }
    dispatch(setLoading(isLoading));
    handleGet(
      url,
      (res, isTrue) => {
        dispatch(setData(res));
        dispatch(setLoading(false));
      },
      isClear
    );
  };
};

export const getDownloadRekapTransaksiAction = (
  date = moment().format("YYYY-MM-DD"),
  callback = null
) => {
  return async (dispatch) => {
    let url = `core/admin/transaction/recap?date=${date}`;
    dispatch(setLoadingDownload(true));
    handleGet(url, (res, isTrue) => {
      dispatch(setDataDownload(res));
      dispatch(setLoadingDownload(false));
      callback(res);
    });
  };
};
