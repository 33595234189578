import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
  CoffeeOutlined,
  AppstoreOutlined,
  CarOutlined,
  ShopOutlined,
  DesktopOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { HRD_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "master-karyawan",
    path: `${HRD_PREFIX_PATH}/karyawan`,
    title: "Karyawan",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-plafond",
    path: `${HRD_PREFIX_PATH}/plafond`,
    title: "Plafond",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
