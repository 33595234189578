import { LOCATIONS } from "../../../constants/admin/masterdata/constants";

const initialState = {
  loadingGetLocation: true,
  loadingPostLocation: false,
  dataLocation: [],

  loadingGetLocationPoint: true,
  loadingPostLocationPoint: false,
  dataLocationPoint: [],
};

export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONS.GET_DATA_LOCATION_SUCCESS:
      return {
        ...state,
        dataLocation: action.data,
      };
    case LOCATIONS.LOADING_DATA_LOCATION_SUCCESS:
      return {
        ...state,
        loadingGetLocation: action.load,
      };
    case LOCATIONS.LOADING_POST_LOCATION_SUCCESS:
      return {
        ...state,
        loadingPostLocation: action.load,
      };

    case LOCATIONS.GET_DATA_LOCATION_POINT_SUCCESS:
      return {
        ...state,
        dataLocationPoint: action.data,
      };
    case LOCATIONS.LOADING_DATA_LOCATION_POINT_SUCCESS:
      return {
        ...state,
        loadingGetLocationPoint: action.load,
      };
    case LOCATIONS.LOADING_POST_LOCATION_POINT_SUCCESS:
      return {
        ...state,
        loadingPostLocationPoint: action.load,
      };
    default:
      return state;
  }
};
