import { CASHIER_SALES } from "../../constants/kasir/constant";

const initialState = {
  loadingGetSalesCashier: true,
  dataGetSalesCashier: [],
  loadingGetSalesListCashier: true,
  dataGetSalesListCashier: [],
};

export const salesCashierReducer = (state = initialState, action) => {
  switch (action.type) {
    case CASHIER_SALES.GET_SALES_CASHIER:
      return {
        ...state,
        dataGetSalesCashier: action.data,
      };
    case CASHIER_SALES.LOADING_GET_SALES_CASHIER:
      return {
        ...state,
        loadingGetSalesCashier: action.load,
      };
    case CASHIER_SALES.GET_SALES_LIST_CASHIER:
      return {
        ...state,
        dataGetSalesListCashier: action.data,
      };
    case CASHIER_SALES.LOADING_GET_SALES_LIST_CASHIER:
      return {
        ...state,
        loadingGetSalesListCashier: action.load,
      };
    default:
      return state;
  }
};
