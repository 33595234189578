import { KARYAWAN } from "../../../constants/hrd/constants";

const initialState = {
  loadingGetKaryawan: true,
  loadingPostKaryawan: false,
  dataKaryawan: [],
};

export const karyawanReducer = (state = initialState, action) => {
  switch (action.type) {
    case KARYAWAN.GET_DATA_KARYAWAN_SUCCESS:
      return {
        ...state,
        dataKaryawan: action.data,
      };
    case KARYAWAN.LOADING_DATA_KARYAWAN_SUCCESS:
      return {
        ...state,
        loadingGetKaryawan: action.load,
      };
    case KARYAWAN.LOADING_POST_KARYAWAN_SUCCESS:
      return {
        ...state,
        loadingPostKaryawan: action.load,
      };
    default:
      return state;
  }
};
