import { VERIFIKASI_USER } from "../../../constants/admin/masterdata/constants";

const initialState = {
  loadingGetVerifikasiUser: true,
  loadingPostVerifikasiUser: false,
  dataVerikasiUser: [],
};

export const verifikasiUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFIKASI_USER.GET_DATA_VERIFIKASI_USER_SUCCESS:
      return {
        ...state,
        dataVerikasiUser: action.data,
      };
    case VERIFIKASI_USER.LOADING_DATA_VERIFIKASI_USER_SUCCESS:
      return {
        ...state,
        loadingGetVerifikasiUser: action.load,
      };
    case VERIFIKASI_USER.LOADING_POST_VERIFIKASI_USER_SUCCESS:
      return {
        ...state,
        loadingPostVerifikasiUser: action.load,
      };

    default:
      return state;
  }
};
