import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import navigationHrdConfig from "configs/NavigationHrdConfig";
import { HRD_PREFIX_PATH } from "configs/AppConfig";
export const HrdViews = () => {
  const [stnk, setStnk] = useState(false);
  const getData = () => {
    axios
      .get(env.API + "/admin/auth/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const data = res.data.data.role.permissions;
        data.forEach((d) => {
          if (d == "MANAGE_STNK") {
            setStnk(true);
          }
        });
      })
      .catch((e) => {});
  };
  useEffect(() => {
    getData();
  }, []);

  console.log("hrd level")

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${HRD_PREFIX_PATH}/karyawan`}
          component={lazy(() => import("./master-karyawan"))}
        />
        <Route
            path={`${HRD_PREFIX_PATH}/plafond`}
            component={lazy(() => import("./master-plafond"))}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(HrdViews);
