import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
  CoffeeOutlined,
  GiftOutlined,
  AlignLeftOutlined,
  PieChartOutlined,
  DownSquareOutlined,
  FileOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

export const routeConfigDanaProyek = [
  { path: "dana-proyek/dashboard", folder: "dana-proyek/dashboard" },
  {
    path: "dana-proyek/detail/dashboard/:id",
    folder: "dana-proyek/dashboard/detail",
  },
  {
    path: "dana-proyek/pengajuan-dana-proyek",
    folder: "dana-proyek/pengajuan-dana-proyek",
  },
  {
    path: "dana-proyek/proyek-saya",
    folder: "dana-proyek/proyek-saya",
  },
  {
    path: "dana-proyek/ajukan-pengembalian-dana",
    folder: "dana-proyek/ajukan-pengembalian-dana",
  },

  {
    path: `dana-proyek/pengembalian-dana`,
    folder: `dana-proyek/pengembalian-dana`,
  },
  {
    path: `dana-proyek/membuat-laporan-proyek`,
    folder: `dana-proyek/membuat-laporan-proyek`,
  },
  {
    path: `dana-proyek/laporan-proyek-saya`,
    folder: `dana-proyek/laporan-proyek-saya`,
  },
  {
    path: `dana-proyek/daftar-anggota-baru`,
    folder: `dana-proyek/daftar-anggota-baru`,
  },
  {
    path: `dana-proyek/daftar-anggota-aktif`,
    folder: `dana-proyek/daftar-anggota-aktif`,
  },
  {
    path: `dana-proyek/detail/dashboard/:id`,
    folder: `dana-proyek/dashboard/detail`,
  },
  {
    path: `dana-proyek/detail/pengembalian-dana/:id`,
    folder: `dana-proyek/pengembalian-dana/detail`,
  },
  {
    path: `dana-proyek/detail/laporan-proyek-saya/:id`,
    folder: `dana-proyek/laporan-proyek-saya/detail`,
  },
  {
    path: `dana-proyek/detail/daftar-anggota-aktif/:id`,
    folder: `dana-proyek/daftar-anggota-aktif/detail`,
  },
  // "dana-proyek/dashboard",
  // "dana-proyek/pengajuan-dana-proyek",
  // "dana-proyek/proyek-saya",
  // "dana-proyek/ajukan-pengembalian-dana",
  // `dana-proyek/pengembalian-dana`,
  // `dana-proyek/membuat-laporan-proyek`,
  // `dana-proyek/laporan-proyek-saya`,
  // `dana-proyek/daftar-anggota-baru`,
  // `dana-proyek/daftar-anggota-aktif`,
];

const dashBoardNavTree = [
  {
    key: "dana-proyek/dashboard",
    path: `${ADMIN_PREFIX_PATH}/dana-proyek/dashboard`,
    title: "Dashboard",
    icon: PieChartOutlined,
    submenu: [],
  },
  {
    key: "daftar-proyek",
    path: `daftar-proyek`,
    title: "Daftar Proyek",
    icon: AlignLeftOutlined,
    submenu: [
      {
        key: "dana-proyek/pengajuan-dana-proyek",
        path: `${ADMIN_PREFIX_PATH}/dana-proyek/pengajuan-dana-proyek`,
        title: "Pengajuan Dana Proyek",
        icon: GiftOutlined,
        submenu: [],
      },
      {
        key: "dana-proyek/proyek-saya",
        path: `${ADMIN_PREFIX_PATH}/dana-proyek/proyek-saya`,
        title: "Proyek Saya",
        icon: GiftOutlined,
        submenu: [],
      },
    ],
  },
  {
    key: "pengembalian-dana",
    path: `pengembalian-dana`,
    title: "Pengembalian Dana",
    icon: DownSquareOutlined,
    submenu: [
      {
        key: "dana-proyek/ajukan-pengembalian-dana",
        path: `${ADMIN_PREFIX_PATH}/dana-proyek/ajukan-pengembalian-dana`,
        title: "Ajukan Pengembalian Dana",
        icon: GiftOutlined,
        submenu: [],
      },
      {
        key: `dana-proyek/pengembalian-dana`,
        path: `${ADMIN_PREFIX_PATH}/dana-proyek/pengembalian-dana`,
        title: "Pengembalian Dana",
        icon: GiftOutlined,
        submenu: [],
      },
    ],
  },
  {
    key: "laporan-proyek",
    path: `laporan-proyek`,
    title: "Laporan Proyek",
    icon: FileOutlined,
    submenu: [
      {
        key: `dana-proyek/membuat-laporan-proyek`,
        path: `${ADMIN_PREFIX_PATH}/dana-proyek/membuat-laporan-proyek`,
        title: "Membuat Laporan Proyek",
        icon: GiftOutlined,
        submenu: [],
      },
      {
        key: `dana-proyek/laporan-proyek-saya`,
        path: `${ADMIN_PREFIX_PATH}/dana-proyek/laporan-proyek-saya`,
        title: "Laporan Proyek Saya",
        icon: GiftOutlined,
        submenu: [],
      },
    ],
  },

  {
    key: "verifikasi-anggota",
    path: `verifikasi-anggota`,
    title: "Verifikasi Anggota",
    icon: SafetyOutlined,
    submenu: [
      {
        key: `dana-proyek/daftar-anggota-baru`,
        path: `${ADMIN_PREFIX_PATH}/dana-proyek/daftar-anggota-baru`,
        title: "Daftar Anggota Baru",
        icon: GiftOutlined,
        submenu: [],
      },
      {
        key: `dana-proyek/daftar-anggota-aktif`,
        path: `${ADMIN_PREFIX_PATH}/dana-proyek/daftar-anggota-aktif`,
        title: "Daftar Anggota Aktif",
        icon: GiftOutlined,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
