import { REKAP_TRANSAKSI } from "../../constants/admin/masterdata/constants";

const initialState = {
  loadingDownload: false,
  loadingGetRekapTransaksi: true,
  dataRekapTransaksi: [],
  dataDownload: [],
};

export const rekapTransaksiReducer = (state = initialState, action) => {
  switch (action.type) {
    case REKAP_TRANSAKSI.DOWNLOAD_DANA_POTONGAN:
      return {
        ...state,
        dataDownload: action.data,
      };
    case REKAP_TRANSAKSI.GET_DATA_REKAP_TRANSAKSI_USER_SUCCESS:
      return {
        ...state,
        dataRekapTransaksi: action.data,
      };
    case REKAP_TRANSAKSI.LOADING_DATA_REKAP_TRANSAKSI_USER_SUCCESS:
      return {
        ...state,
        loadingGetRekapTransaksi: action.load,
      };
    case REKAP_TRANSAKSI.LOADING_DOWNLOAD_DANA_POTONGAN:
      return {
        ...state,
        loadingDownload: action.load,
      };
    default:
      return state;
  }
};
