import {
  RetweetOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  CoffeeOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "master-voucher",
    path: `${ADMIN_PREFIX_PATH}/master-voucher`,
    title: "Data Voucher",
    icon: GiftOutlined,
    permissions: [
      'ASSIGN_VOUCHERS', 
      'CREATE_VOUCHERS',
      'DELETE_VOUCHERS',
      'GET_LIST_USER_VOUCHERS',
      'GET_VOUCHERS',
      'UPDATE_STATUS_VOUCHERS',
      'UPDATE_VOUCHERS',
      'UPDATE_VOUCHERS_IMAGE',
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-lokasi",
    path: `${ADMIN_PREFIX_PATH}/master-lokasi`,
    title: "Data Lokasi Kafe",
    icon: DatabaseOutlined,
    permissions: [
      'CREATE_LOCATION_POS', 
      'DELETE_LOCATION_POS',
      'GET_LOCATION_POS',
      'UPDATE_LOCATION_POS',
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-delivery",
    path: `${ADMIN_PREFIX_PATH}/master-delivery`,
    title: "Data Titik Pengantaran",
    icon: DatabaseOutlined,
    permissions: [
      'CREATE_DELIVERY_POINT_POS', 
      'DELETE_DELIVERY_POINT_POS',
      'GET_DELIVERY_POINT_POS',
      'GET_LIST_DELIVERY_POINT_POS',
      'UPDATE_DELIVERY_POINT_POS',
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-delivery-fee",
    path: `${ADMIN_PREFIX_PATH}/master-delivery-fee`,
    title: "Data Biaya Pengantaran",
    icon: DatabaseOutlined,
    permissions: [
      'CREATE_DELIVERY_POINT_LOCATION_POS', 
      'DELETE_DELIVERY_POINT_LOCATION_POS',
      'GET_DELIVERY_POINT_LOCATION_POS',
      'UPDATE_DELIVERY_POINT_LOCATION_POS',
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-kasir",
    path: `${ADMIN_PREFIX_PATH}/master-kasir`,
    title: "Data Kasir",
    icon: DatabaseOutlined,
    permissions: [
      'CREATE_CASHIER_POS', 
      'DELETE_CASHIER_POS',
      'GET_CASHIER_POS',
      'UPDATE_CASHIER_POS',
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "data-vendor",
    path: `${ADMIN_PREFIX_PATH}/cafe/data-vendor`,
    title: "Data Vendor",
    icon: CoffeeOutlined,
    permissions: [
      'CREATE_VENDOR_POS', 
      'DELETE_VENDOR_POS',
      'GET_LIST_VENDOR_POS',
      'GET_STATS_VENDOR_POS',
      'GET_VENDOR_POS',
      'IMPORT_VENDOR_POS',
      'UPDATE_VENDOR_POS',
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "rekap-transaksi",
    path: `${ADMIN_PREFIX_PATH}/cafe/rekap-transaksi`,
    title: "Rekap Transaksi",
    icon: RetweetOutlined,
    permissions: [
      'GET_TRANSACTION_CHART_POS', 
      'GET_TRANSACTION_POS',
      'GET_TRANSACTION_RECAP_POS',
      'GET_VENDOR_TRANSACTION_RECAP_POS',
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pencairan-dana",
    path: `${ADMIN_PREFIX_PATH}/cafe/pencairan-dana`,
    title: "Pencairan Dana",
    icon: CreditCardOutlined,
    permissions: [
      'GET_VENDOR_DISBURSEMENTS_POS', 
      'GET_VENDOR_DISBURSEMENTS_STATUS_POS',
    ],
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
