import { PENCAIRAN_DANA_VENDOR_ADMIN } from "../../../constants/admin/cafe/contants";

const initialState = {
  loadingGetPencairanDanaVendor: true,
  loadingPostPencairanDanaVendor: false,
  dataPencairanDanaVendor: [],
};

export const pencairanDanaVendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case PENCAIRAN_DANA_VENDOR_ADMIN.GET_DATA_PENCAIRAN_DANA_SUCCESS:
      return {
        ...state,
        dataPencairanDanaVendor: action.data,
      };
    case PENCAIRAN_DANA_VENDOR_ADMIN.LOADING_DATA_PENCAIRAN_DANA_SUCCESS:
      return {
        ...state,
        loadingGetPencairanDanaVendor: action.load,
      };
    case PENCAIRAN_DANA_VENDOR_ADMIN.LOADING_POST_PENCAIRAN_DANA_SUCCESS:
      return {
        ...state,
        loadingPostPencairanDanaVendor: action.load,
      };
    default:
      return state;
  }
};
