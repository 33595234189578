import { env } from "configs/EnvironmentConfig";
import axios from "axios";
import { message, notification } from "antd";
import { setup } from "axios-cache-adapter";
import memoryDriver from "localforage-memoryStorageDriver";
import localforage from "localforage";
import {
  checkLoading,
  clearNotif,
  handleEmpty,
  showMessage,
  ucWords,
} from "helpers/general_helper";
import { isIE } from "react-device-detect";

const httpClient = axios.create({ timeout: 80000 });
httpClient.defaults.timeout = 500;
axios.defaults.headers.common["Authorization"] =
  // "Beare eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9rb3BrYXIubGF5YW5hbmNlcmRhcy5pZFwvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTY3MDM4NjA1MSwiZXhwIjoxNjcwMzg5NjUxLCJuYmYiOjE2NzAzODYwNTEsImp0aSI6IldYcHZOdnFlaXZIQzc1R1kiLCJzdWIiOiI5N2VhMjA5Yy1jYTQxLTQ3YjYtYjdlYS04ZDM0NThhZjBiNjUiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.ir2KzglC2lCGfbhvccBnPbDdY5ARr0l16JnZLRQ0fTE";
  "Bearer " + localStorage.getItem("token");


const handleCatch = async (err, callback = null) => {
  if (err.response === undefined) {
    callback(true);
    return showMessage("Terjadi kesalahan");
  } else {
    let res = err.response.data;
    if (res.status === 401) {
      axios
        .post(
          env.API + "/user/auth/login",
          {
            email: localStorage.emailUser,
            password: localStorage.passwordUser,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((newRes) => {
          axios.defaults.headers.common["Authorization"] =
            newRes.data.data.token;
          localStorage.setItem("token", newRes.data.data.token);
          setTimeout(() => {
            // window.location.reload();
          });
        });
    }
    if (res.data !== undefined) {
      callback(true);

      if (res.code === "UNAUTHORIZED") {
        const key = `open${Date.now()}`;

        await deleteDb(null);
        setTimeout(() => {
          if (localStorage.role === "POS_CASHIER") {
            axios
              .post(
                env.API + "/user/auth/login",
                {
                  email: localStorage.emailUser,
                  password: localStorage.passwordUser,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((newRes) => {
                axios.defaults.headers.common["Authorization"] =
                  newRes.data.data.token;
                localStorage.setItem("token", newRes.data.data.token);
                setTimeout(() => {
                  window.location.reload();
                });
              });
          } else {
            notification.error({
              message: "Sesi Sudah Berkahir",
              description: "Anda Akan Dialihkan Kehalaman Login",
              key,
              onClose: () => {},
            });
            //logout
            localStorage.removeItem("token");
            window.location.href = "/auth/login";
            axios
              .post(env.API + "/user/auth/logout", {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then((res) => {
                if(res){
                  localStorage.removeItem("token");
                  window.location.href = "/auth/login";
                }
              })
              .catch((e) => {});
            if (localStorage.role === "ADMIN") {
              window.location.href = "/auth/admin/login";
            } else {
              window.location.href = "/auth/login";
            }
          }
        }, 1000);
      } else if (typeof res.data === "string") {
        return showMessage(ucWords(res.data));
      } else {
        if (res.data === null) {
          return showMessage(
            ucWords(`${err.response.config.url} - ${res.code}`)
          );
        } else {
          let dataMsg = Object.keys(res.data);
          for (let i = 0; i < dataMsg.length; i++) {
            return showMessage(ucWords(res.data[dataMsg[i]][0]));
          }
        }
      }
    }
  }
};

export async function configure(base = "") {

  await localforage.defineDriver(memoryDriver);
  const forageStore = localforage.createInstance({
    driver: [
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
      memoryDriver._driver,
      localforage.WEBSQL,
    ],
    version: 1.0,
    size: 4980736,
    // name: dbName,
  });
  return setup({
    readOnError: (e) => {
      console.log("error setup cache", e);
    },
    cache: {
      clearOnStale: false,
      exclude: {
        query: false,
        methods: ["put", "patch", "delete"],
      },

      debug: false,
      store: forageStore,
      maxAge: 24 * 60 * 60 * 1000, //1 hari
    },
  });
}
export const deleteDb = async (url = "") => {
  const forageStore = localforage.createInstance({
    // name: dbName,
  });

  if (url !== null) {
    const getGekys = await forageStore.keys();
    const checkUrl = getGekys.filter((row) => row.includes(url));
    if (checkUrl.length > 0) {
      checkUrl.map(async (row) => await forageStore.removeItem(row));
    }
  } else {
    await forageStore.clear();
  }
};

export const handleGet = async (url, callback, isClear = true,isShowMessage=true) => {

  const baseUrl = env.API + "/" + url;
  const splitUrl = baseUrl.split("?");
  await deleteDb(splitUrl[0]);
  configure(splitUrl[0])
    .then(async (api) => {
      const response = await api.get(baseUrl, {
        clearCacheEntry: isClear,
      });
      const datum = response.data.data;
      callback(datum, true);
    })
    .catch((err) => {
      if(isShowMessage){
        // handleCatch(err, (isConnect) => {
        //   callback([], false);
        //   clearNotif();
        // });
      }
      else{
        callback(err.response, false);
      }

    });
};

export const handlePost = async (
  url,
  data,
  callback,
  isMsg = true,
  msg = ""
) => {
  await deleteDb(url);
  axios
    .post(env.API + "/" + url, data)
    .then((res) => {
      let datum = res.data;
      callback(datum.data, true);
      if (isMsg) {
        showMessage("Data Berhasil Ditambahkan", "success");
        clearNotif();
      }
    })
    .catch((err) => {
      if (isMsg) {
        handleCatch(err, (isConnect) => {
          callback([], false);
          clearNotif();
        });
      } else {
        callback([], false);
      }
    });
};

export const handlePut = async (url, data, callback, isMsg = true) => {
  await deleteDb(url);
  axios
    .put(env.API + "/" + url, data)
    .then((res) => {
      let datum = res.data;
      callback(datum.data, true);
      if (isMsg) {
        showMessage("Data Berhasil Diubah", "success");
      }
      clearNotif();
    })
    .catch((err) => {
      if (isMsg) {
        handleCatch(err, (isConnect) => {
          callback([], false);
          clearNotif();
        });
      } else {
        callback([], false);
      }
    });
};

export const handleDelete = async (url, data, callback, isMsg = true) => {
  await deleteDb(url);
  axios
    .delete(env.API + "/" + url, {
      data: data,
    })
    .then(async (res) => {
      callback(true);
      if (isMsg) {
        showMessage("Data Berhasil Dihapus", "success");
      }
      clearNotif();
    })
    .catch((err) => {
      handleCatch(err, (isConnect) => {
        callback([], false);
        clearNotif();
      });
    });
};
