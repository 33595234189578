import { LOCATIONS } from "../../../constants/admin/masterdata/constants";

const initialState = {
  loadingGetLocationFee: true,
  loadingPostLocationFee: false,
  dataLocationFee: [],
};

export const locationPointReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONS.GET_DATA_LOCATION_FEE_SUCCESS:
      return {
        ...state,
        dataLocationFee: action.data,
      };
    case LOCATIONS.LOADING_DATA_LOCATION_FEE_SUCCESS:
      return {
        ...state,
        loadingGetLocationFee: action.load,
      };
    case LOCATIONS.LOADING_POST_LOCATION_FEE_SUCCESS:
      return {
        ...state,
        loadingPostLocationFee: action.load,
      };

    default:
      return state;
  }
};
