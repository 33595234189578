import {
    PlusOutlined,
    CheckSquareOutlined,
    AuditOutlined,
    BookOutlined
  } from "@ant-design/icons";
  import { ADMIN_PREFIX_PATH } from "configs/AppConfig";
  
  const dashBoardNavTree = [
    {
      key: "add-article",
      path: `${ADMIN_PREFIX_PATH}/add-article`,
      title: "Tambah Artikel",
      icon: PlusOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "daftar-article",
      path: `${ADMIN_PREFIX_PATH}/daftar-article`,
      title: "Daftar Artikel",
      icon: BookOutlined,
      breadcrumb: false,
      submenu: [],
    },
    // {
    //     key: "koperasi",
    //     path: `${ADMIN_PREFIX_PATH}/koperasi`,
    //     title: "Koperasi",
    //     // icon: AuditOutlined,
    //     breadcrumb: false,
    //     submenu: [],
    //   },
    //   {
    //     key: "role",
    //     path: `${ADMIN_PREFIX_PATH}/role`,
    //     title: "Role",
    //     // icon: BookOutlined,
    //     breadcrumb: false,
    //     submenu: [],
    //   },
      // {
      //   key: "permission",
      //   path: `${ADMIN_PREFIX_PATH}/permission`,
      //   title: "Permission",
      //   icon: CheckSquareOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      
  ];
  
  const navigationRootArticleConfig = [...dashBoardNavTree];
  
  export default navigationRootArticleConfig;