import { CASHIER_DELIVERY_BATCH } from "../../constants/kasir/constant";

const initialState = {
  loadingGetCashierDeliveryBatch: true,
  loadingPostCashierDeliveryBatch: false,
  dataGetCashierDeliveryBatch: [],
};

export const deliveryBatchCashierReducer = (state = initialState, action) => {
  switch (action.type) {
    case CASHIER_DELIVERY_BATCH.GET_CASHIER_DELIVERY_BATCH:
      return {
        ...state,
        dataGetCashierDeliveryBatch: action.data,
      };
    case CASHIER_DELIVERY_BATCH.LOADING_GET_CASHIER_DELIVERY_BATCH:
      return {
        ...state,
        loadingGetCashierDeliveryBatch: action.load,
      };
    case CASHIER_DELIVERY_BATCH.LOADING_POST_CASHIER_DELIVERY_BATCH:
      return {
        ...state,
        loadingPostCashierDeliveryBatch: action.load,
      };
    default:
      return state;
  }
};
