import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
// import navigationKasirConfig from "configs/NavigationKasirConfig";
import NavigationKitcherConfig from "configs/NavigationKitcherConfig";
import { KASIR_PREFIX_PATH } from "configs/AppConfig";
export const KasirViews = () => {
  const [stnk, setStnk] = useState(false);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {NavigationKitcherConfig.map((res, i) => {
          return (
            <Route
              key={i}
              path={res.path}
              component={lazy(() => import("./" + res.key + "/index"))}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default React.memo(KasirViews);
