import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import navigationKasirConfig from "configs/NavigationKasirConfig";
import { KASIR_PREFIX_PATH } from "configs/AppConfig";
import { handleGet } from "redux/actions/baseAction";
export const KasirViews = () => {
  const [stnk, setStnk] = useState(false);
  useEffect(() => {
    handleGet("pos/pos/cashier/location", (res) => {
      localStorage.setItem("statusClosing", res.is_open ? "OPEN" : "CLOSE");
    });
  }, []);
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {navigationKasirConfig.map((res, i) => {
          return (
            <Route
              key={i}
              path={res.path}
              component={lazy(() => import("./" + res.key + "/index"))}
            />
          );
        })}

        <Route
          path={`${KASIR_PREFIX_PATH}/delivery-detail/:id`}
          component={lazy(() => import("./delivery/detail/index"))}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(KasirViews);
