import React, { Component, useEffect } from "react";
import { SettingOutlined } from "@ant-design/icons";
import {
  Drawer,
  Menu,
  Dropdown,
  Divider,
  Popover,
  Button,
  Avatar,
  Badge,
} from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { DownOutlined, BellOutlined, UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { DIR_RTL } from "constants/ThemeConstant";
import { NavNotification } from "./NavNotification";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import { handleGet, handlePost } from "redux/actions/baseAction";
import { setLoading } from "redux/actions/admin/rekapTransaksi.action";
import { rmU } from "helpers/general_helper";

const text = <span>Notification</span>;
const content = (
  <div>
    <p>Content</p>
    <p>Content</p>
  </div>
);

export class NavPanel extends Component {
  state = { visible: false, name: "", role: "", loadingLogout: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  getProfile = () => {
    axios
      .get(env.API + "/admin/auth/user", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        localStorage.setItem("name", res.data.data.name);
        localStorage.setItem("role", res.data.data.role);
        this.setState({
          name: res.data.data.name,
          role: res.data.data.role,
        });
      })
      .catch((e) => {
        localStorage.removeItem("token");
        //logout
        localStorage.removeItem("token");
        window.location.href = "/auth/login";
        axios
          .post(env.API + "/user/auth/logout", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            if(res){
              localStorage.removeItem("token");
              window.location.href = "/auth/login";
            }
          })
          .catch((e) => {});
      });
  };

  componentDidMount() {
    this.setState({
      name: localStorage.getItem("name"),
      role: localStorage.getItem("role"),
    });
  }

  render() {
    const menu = (
      <Menu>
        {/* <Menu.Item>
      <UserOutlined />
      <Divider type="vertical" />
      <a target="" rel="noopener noreferrer" href="/anggota/edit-profile">
        Profile
      </a>
    </Menu.Item> */}
        <Menu.Item
          style={{ borderTop: "1px solid #F6F6F6" }}
          onClick={() => {
            this.setState({ loadingLogout: true }, () => {
              axios
                .delete(env.API + "/notification/subscriber", {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                  data: { type: "WEB" },
                })
                .then((_) => {
                  handleGet("pos/pos/cashier/location", (res) => {
                    if (res.is_open) {
                      handlePost(
                        "pos/pos/cashier/location/status",
                        { status: "CLOSE" },
                        (datum, isTrue) => {
                          localStorage.removeItem("statusClosing");
                          localStorage.removeItem("timeClosing");
                          localStorage.removeItem("statusClosing");
                          //logout
                          localStorage.removeItem("token");
                          window.location.href = "/auth/login";
                          axios
                            .post(env.API + "/user/auth/logout", {
                              headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                              },
                            })
                            .then((res) => {
                              if(res){
                                localStorage.removeItem("token");
                                window.location.href = "/auth/login";
                              }
                            })
                            .catch((e) => {});
                        },
                        false,
                        "-"
                      );
                    } else {
                      //logout
                      localStorage.removeItem("token");
                      window.location.href = "/auth/login";
                      axios
                        .post(env.API + "/user/auth/logout", {
                          headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                          },
                        })
                        .then((res) => {
                          if(res){
                            localStorage.removeItem("token");
                            window.location.href = "/auth/login";
                          }
                        })
                        .catch((e) => {});
                    }
                  });
                })
                .catch((e) => {
                  //logout
                  localStorage.removeItem("token");
                  window.location.href = "/auth/login";
                  axios
                    .post(env.API + "/user/auth/logout", {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                    .then((res) => {
                      if(res){
                        localStorage.removeItem("token");
                        window.location.href = "/auth/login";
                      }
                    })
                    .catch((e) => {});
                });
            });
          }}
        >
          <center>
            <strong>
              <a
                style={{ color: "#EB2935" }}
                rel="noopener noreferrer"
                // href="/auth/login"
              >
                {this.state.loadingLogout ? "Mohon Tunggu .." : "Logout"}
              </a>
            </strong>
          </center>
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        {/* <div style={{ marginRight: "20px", fontSize: "24px" }}>
          <Popover
            placement="bottom"
            title={text}
            content={content}
            trigger="click"
          >
            <BellOutlined />
          </Popover>
        </div> */}
        <div style={{ marginRight: "10px", fontSize: "24px" }}>
          <NavNotification />
        </div>

        <Dropdown
          overlay={menu}
          trigger={["click"]}
          visible={this.state.visible}
          onVisibleChange={(e) => {
            if (this.state.loadingLogout) {
              this.setState({ visible: true });
            }
          }}
        >
          <a
            className="ant-dropdown-link"
            style={{ color: "black", marginTop: "-10px", marginRight: "30px" }}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ visible: !this.state.visible });
            }}
          >
            <p style={{ position: "absolute", top: "30px" }}>
              {" "}
              {rmU(this.state.role)}
            </p>
            <strong>{this.state.name}</strong> <DownOutlined />
          </a>
        </Dropdown>
        {/* <Menu mode="horizontal">
          <Menu.Item key="panel" onClick={this.showDrawer}>
            <span><SettingOutlined className="nav-icon mr-0" /></span>
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement={this.props.direction === DIR_RTL ? 'left' : 'right'} 
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator/>
        </Drawer> */}
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(NavPanel);
