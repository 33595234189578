import {
  HomeOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "manage_pengajuan",
    path: `${ADMIN_PREFIX_PATH}/home`,
    title: "Manage Pengajuan",
    icon: HomeOutlined,
    permissions: [
      'ACCEPT_LOAN',
      'CHECK_USER_LOAN_CONFIRMATION',
      'CONFIRM_LOAN',
      'DELIVERY_TIME_CHECK',
      'GET_BAI_BARANG_LOAN',
      'GET_LOANS',
      'GET_RELEASED_TRANSCRIPT',
      'GET_BAI_BARANG_PO',
      'GET_BAI_BARANG_PO_DETAIL',
      'GET_LOANS_MONTHLY_BY_TYPE',
      'RELEASE_LOAN',
      'TRANSFER_LOAN',
    ],
    breadcrumb: false,
    submenu: [],
  },
];

const navigationAllConfig = [...dashBoardNavTree];

export default navigationAllConfig;
