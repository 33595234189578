import { CASHIER } from "../../../constants/admin/masterdata/constants";

const initialState = {
  loadingGetCashier: true,
  loadingPostCashier: false,
  dataCashier: [],
};

export const cashierReducer = (state = initialState, action) => {
  switch (action.type) {
    case CASHIER.GET_DATA_CASHIER_SUCCESS:
      return {
        ...state,
        dataCashier: action.data,
      };
    case CASHIER.LOADING_DATA_CASHIER_SUCCESS:
      return {
        ...state,
        loadingGetCashier: action.load,
      };
    case CASHIER.LOADING_POST_CASHIER_SUCCESS:
      return {
        ...state,
        loadingPostCashier: action.load,
      };
    default:
      return state;
  }
};
