import { BANKS } from "../../../constants/admin/masterdata/constants";

const initialState = {
  loadingGetBank: true,
  dataBank: [],
};

export const bankReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANKS.GET_DATA_BANKS_SUCCESS:
      return {
        ...state,
        dataBank: action.data,
      };
    case BANKS.LOADING_DATA_BANKS_SUCCESS:
      return {
        ...state,
        loadingGetBank: action.load,
      };
    default:
      return state;
  }
};
