import { CASHIER_STOCK } from "../../constants/kasir/constant";

const initialState = {
  loadingGetStockCashier: true,
  dataGetStockCashier: [],
  loadingGetStockListCashier: true,
  dataGetStockListCashier: [],
};

export const stockCashierReducer = (state = initialState, action) => {
  switch (action.type) {
    case CASHIER_STOCK.GET_STOCK_CASHIER:
      return {
        ...state,
        dataGetStockCashier: action.data,
      };
    case CASHIER_STOCK.LOADING_GET_STOCK_CASHIER:
      return {
        ...state,
        loadingGetStockCashier: action.load,
      };
    case CASHIER_STOCK.GET_STOCK_LIST_CASHIER:
      return {
        ...state,
        dataGetStockListCashier: action.data,
      };
    case CASHIER_STOCK.LOADING_GET_STOCK_LIST_CASHIER:
      return {
        ...state,
        loadingGetStockListCashier: action.load,
      };
    default:
      return state;
  }
};
