import { PENCAIRAN_VENDOR } from "../../constants/vendor/constant";

const initialState = {
  loadingPencairanVendor: true,
  dataPencairanVendor: [],
};

export const pencairanVendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case PENCAIRAN_VENDOR.GET_PENCAIRAN_VENDOR:
      return {
        ...state,
        dataPencairanVendor: action.data,
      };
    case PENCAIRAN_VENDOR.LOADING_GET_PENCAIRAN_VENDOR:
      return {
        ...state,
        loadingPencairanVendor: action.load,
      };
    default:
      return state;
  }
};
