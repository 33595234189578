import axios from "axios";

export const Types = {
  GET_PRODUCT_REQUEST: "GET_PRODUCT_REQUEST",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  LOADING_PRODUCT_SUCCESS: "LOADING_PRODUCT_SUCCESS",
};

export const loadingProductsSuccess = (items) => ({
  type: Types.LOADING_PRODUCT_SUCCESS,
  payload: { items },
});
export const setDataProduct = (data) => {
  return {
    type: Types.GET_PRODUCT_SUCCESS,
    data,
  };
};
export const productAction = () => {
  return (dispatch) => {
    dispatch(loadingProductsSuccess(true));
    axios
      .get(
        "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
      )
      .then((res) => {
        dispatch(setDataProduct(res.data));
        dispatch(loadingProductsSuccess(false));
      });
  };
};
