import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

let showMenu = true;
console.log(localStorage.getItem('is_ctu'))
if (localStorage.getItem("is_ctu") === 1) {
  console.log("aaa")
  showMenu = false;
} else {
  showMenu = true;
}

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "Info Bulan Berjalan",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
    isShow: true
  },
  {
    key: "riwayat-bulanan",
    path: `${APP_PREFIX_PATH}/riwayat-bulanan`,
    title: "Riwayat Bulanan",
    icon: RetweetOutlined,
    breadcrumb: false,
    submenu: [],
    isShow: true
  },
  {
    key: "angsuran",
    path: `${APP_PREFIX_PATH}/angsuran`,
    title: "Angsuran",
    icon: CreditCardOutlined,
    breadcrumb: false,
    submenu: [],
    isShow: true
  },
  {
    key: "spsw",
    path: `${APP_PREFIX_PATH}/spsw`,
    title: "Riwayat & Saldo SPSW",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [],
    isShow: true
  },
];

const is_ctu = localStorage.getItem('is_ctu')

if(is_ctu != 1){
  dashBoardNavTree.push(
    {
      key: "add-kendaraan",
      path: `${APP_PREFIX_PATH}/list-kendaraan`,
      title: "Tambah Kendaraan",
      icon: PlusSquareOutlined,
      breadcrumb: false,
      submenu: [],
      isShow: showMenu,
    },
    {
      key: "pengajuan-pinjaman",
      path: `${APP_PREFIX_PATH}/pengajuan-pinjaman`,
      title: "Pengajuan Pinjaman",
      icon: PlusSquareOutlined,
      breadcrumb: false,
      submenu: [],
      isShow: showMenu,
    },)
}

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
