import { CASHIER_TRANSACTION } from "../../constants/kasir/constant";
import { handleGet, handlePost, handlePut } from "redux/actions/baseAction";
import { POS_PREFIX_PATH } from "configs/AppConfig";
import { checkLoading, getOffset, PAGINATION } from "helpers/general_helper";
import { IndexedDB, AccessDB, useIndexedDB } from "react-indexed-db";

export const setClosing = (load) => ({
  type: CASHIER_TRANSACTION.CLOSE_CASHIER_TRANSACTION,
  load,
});
export const setLoading = (load) => ({
  type: CASHIER_TRANSACTION.LOADING_STORE_CASHIER_TRANSACTION,
  load,
});
export const setData = (data) => {
  return {
    type: CASHIER_TRANSACTION.LOADING_STORE_CASHIER_TRANSACTION,
    data,
  };
};
export const setLoadingTransactionPending = (load) => ({
  type: CASHIER_TRANSACTION.LOADING_GET_CASHIER_TRANSACTION_PENDING,
  load,
});
export const setDataTransactionPending = (data) => {
  return {
    type: CASHIER_TRANSACTION.GET_CASHIER_TRANSACTION_PENDING,
    data,
  };
};

export const storeTransactionCashierAction = (data, callback) => {
  return async (dispatch) => {
    let url = `pos/pos/cashier/transaction`;
    dispatch(setLoading(true));
    handlePost(
      url,
      data,
      (res, isTrue) => {
        if (isTrue) {
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
        }
        callback(res, isTrue);
      },
      false
    );
  };
};

export const getCashierTransactionPendingAction = (
  limit = PAGINATION.limit,
  offset = PAGINATION.offset,
  query = "",
  callback = null
) => {
  return async (dispatch) => {
    const newOffset = getOffset(offset, limit);
    let url = `pos/pos/cashier/transaction?limit=${25}&offset=${newOffset}&status=PENDING`;
    dispatch(setLoadingTransactionPending(true));
    handleGet(url, (res) => {
      const tableHold = useIndexedDB("hold");
      tableHold.getAll().then((e) => {
        const newDatas = e.filter((v) => v.selected);
        newDatas.map((v) => {
          tableHold.deleteRecord(v.id).then((val) => {});
        });
      });
      if (callback !== null) callback(res);
      if (res.data.length > 0) {
        console.log("res pending",res.data)
        res.data.map((obj, key) => {
          let newData = [];
          // console.log("obj pending",obj)
          obj.items.map((row, i) => {
            newData.push({
              id: Math.floor(Math.random()),
              id_old: row.item.id,
              code: row.item.code,
              created_at: row.item.created_at,
              deleted_at: row.item.deleted_at,
              durability: row.item.durability,
              location_id: row.item.location_id,
              name: row.item.name,
              purchase_price: row.item.purchase_price,
              selling_price: row.item.selling_price,
              stocks: 0,
              qty: row.quantity,
              amount: row.amount
            });
          });
          let col = {
            id_pending: obj.id,
            id_kasir: localStorage.id,
            kode: obj.code,
            atasNama: `PENDING KE ${key + 1}`,
            produk: newData,
            createdAt: obj.created_at,
            selected: true,
          };
          tableHold.add(col).then((event) => {});
          dispatch(setDataTransactionPending(res.data));
        });
      }
      dispatch(setLoadingTransactionPending(false));
    });
  };
};
