import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
  CoffeeOutlined,
  AppstoreOutlined,
  CarOutlined,
} from "@ant-design/icons";
import { VENDOR_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${VENDOR_PREFIX_PATH}/dashboard`,
    title: "Dashboard",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pengiriman-produk",
    path: `${VENDOR_PREFIX_PATH}/pengiriman-produk`,
    title: "Pengiriman Produk",
    icon: CarOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "laporan-penjualan",
    path: `${VENDOR_PREFIX_PATH}/laporan-penjualan`,
    title: "Laporan Penjualan",
    icon: RetweetOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pencairan-dana",
    path: `${VENDOR_PREFIX_PATH}/pencairan-dana`,
    title: "Pencairan Dana",
    icon: CreditCardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "data-produk",
    path: `${VENDOR_PREFIX_PATH}/data-produk`,
    title: "Data Produk",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
