import { DEALER } from "../../../constants/admin/masterdata/constants";

const initialState = {
  loadingGetDealer: true,
  loadingPostDealer: false,
  dataDealer: [],
};

export const dealerReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEALER.GET_DATA_DEALER_SUCCESS:
      return {
        ...state,
        dataDealer: action.data,
      };
    case DEALER.LOADING_DATA_DEALER_SUCCESS:
      return {
        ...state,
        loadingGetDealer: action.load,
      };
    case DEALER.LOADING_POST_DEALER_SUCCESS:
      return {
        ...state,
        loadingPostDealer: action.load,
      };
    default:
      return state;
  }
};
