import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import { message, Spin } from "antd";
import { isMobile, isAndroid, isTablet,isIOS } from "react-device-detect";
import { LINK } from "helpers/string_helper";
import { DBConfig } from "./DBConfig";
import { initDB } from "react-indexed-db";
import { handleEmpty } from "helpers/general_helper";
initDB(DBConfig);

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);

  useEffect(() => {
    console.log("window.location.pathname",window.location.pathname)
    // window.location.href='https://apps.apple.com/us/developer/koperasi-karyawan-toyota/id1658453158'

    // window.location.href = "apps.apple.com/us/app/kopkar-toyota/id1658453156";
    // if(window.location.pathname!=='/auth/admin/login'){
        console.log("isIOS",isIOS)
      console.log("isAndroid",isAndroid)
        console.log("isMobile",isMobile)
        console.log("isTablet",isTablet)
      setTimeout(() => {
          const path = window.location.pathname;
          if (
              path === LINK.linkForgot ||
              path === LINK.linkVerify ||
              path === LINK.linkReset
          ) {
          } else {
              if(!isTablet){
                  if(isIOS){
                      message
                          .info("anda akan dialihkan ke halaman apps store")
                          .then(() => {
                              setIsMobileBrowser(false);
                              // window.location='https://kopkartoyota.page.link/redirect'
                          });

                  }
                  if(isAndroid){

                      message
                          .info("anda akan dialihkan ke halaman google play store")
                          .then(() => {
                              setIsMobileBrowser(false);
                              // window.location.href = "https://play.google.com/store/apps/details?id=com.kopkartoyota.apps";
                          });
                  }
              }
              // setIsMobileBrowser(true);

          }


      }, 300);
      //
      // if (isIOS||isMobile) {
      //   const path = window.location.pathname;
      //   if (
      //       path === LINK.linkForgot ||
      //       path === LINK.linkVerify ||
      //       path === LINK.linkReset
      //   ) {
      //   } else {
      //     // setIsMobileBrowser(true);
      //
      //   }
      // }
    // }

  });

  return (
    <div className="App">
      <Spin spinning={isMobileBrowser}>
        <Provider store={store}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Router>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </Router>
          </ThemeSwitcherProvider>
        </Provider>
      </Spin>
    </div>
  );
}

export default App;
