// code: "I-9";
// created_at: "2022-11-01T08:07:01.000000Z";
// deleted_at: null;
// durability: 30;
// id: "97a39ef1-3b88-4fea-a992-b40329739cbd";
// location_id: "974f824e-2d9e-4e6b-925f-dfbc533b7219";
// name: "Nu Green Tea";
// purchase_price: 5000;
// selling_price: 6500;

export const DBConfig = {
  name: "kopkar-kasir",
  version: 1,
  objectStoresMeta: [
    {
      store: "cart",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "is_hold", keypath: "is_hold", options: { unique: false } },
        {
          name: "is_pending",
          keypath: "is_pending",
          options: { unique: false },
        },
        { name: "id_kasir", keypath: "id_kasir", options: { unique: false } },
        { name: "qty", keypath: "qty", options: { unique: false } },
        { name: "id", keypath: "id", options: { unique: false } },
        { name: "id_old", keypath: "id_old", options: { unique: false } },
        { name: "code", keypath: "code", options: { unique: false } },
        {
          name: "created_at",
          keypath: "created_at",
          options: { unique: false },
        },
        {
          name: "deleted_at",
          keypath: "deleted_at",
          options: { unique: false },
        },
        {
          name: "durability",
          keypath: "durability",
          options: { unique: false },
        },
        {
          name: "location_id",
          keypath: "location_id",
          options: { unique: false },
        },
        {
          name: "name",
          keypath: "name",
          options: { unique: false },
        },
        {
          name: "purchase_price",
          keypath: "purchase_price",
          options: { unique: false },
        },
        {
          name: "selling_price",
          keypath: "selling_price",
          options: { unique: false },
        },
        {
          name: "stocks",
          keypath: "stocks",
          options: { unique: false },
        },
      ],
    },
    {
      store: "hold",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "id_kasir", keypath: "id_kasir", options: { unique: false } },
        {
          name: "id_pending",
          keypath: "is_pending",
          options: { unique: false },
        },
        { name: "id", keypath: "id", options: { unique: false } },
        { name: "selected", keypath: "selected", options: { unique: false } },
        { name: "kode", keypath: "kode", options: { unique: false } },
        { name: "atasNama", keypath: "atasNama", options: { unique: false } },
        { name: "Produk", keypath: "Produk", options: { unique: false } },
        {
          name: "created_at",
          keypath: "created_at",
          options: { unique: false },
        },
      ],
    },
  ],
};
