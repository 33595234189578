export const CASHIER_STOCK = {
  GET_STOCK_CASHIER: "GET_STOCK_CASHIER",
  LOADING_GET_STOCK_CASHIER: "LOADING_GET_STOCK_CASHIER",
  GET_STOCK_LIST_CASHIER: "GET_STOCK_LIST_CASHIER",
  LOADING_GET_STOCK_LIST_CASHIER: "LOADING_GET_STOCK_LIST_CASHIER",
};

export const CASHIER_SALES = {
  GET_SALES_CASHIER: "GET_SALES_CASHIER",
  LOADING_GET_SALES_CASHIER: "LOADING_GET_SALES_CASHIER",
  GET_SALES_LIST_CASHIER: "GET_SALES_LIST_CASHIER",
  LOADING_GET_SALES_LIST_CASHIER: "LOADING_GET_SALES_LIST_CASHIER",
};

export const CASHIER_ITEM = {
  GET_ITEM_CASHIER: "GET_ITEM_CASHIER",
  LOADING_GET_ITEM_CASHIER: "LOADING_GET_ITEM_CASHIER",
  LOADING_STORE_ITEM_CASHIER: "LOADING_STORE_ITEM_CASHIER",
};

export const CASHIER_TRANSACTION = {
  CLOSE_CASHIER_TRANSACTION: "CLOSE_CASHIER_TRANSACTION",
  GET_CASHIER_TRANSACTION: "GET_CASHIER_TRANSACTION",
  LOADING_STORE_CASHIER_TRANSACTION: "LOADING_STORE_CASHIER_TRANSACTION",
  GET_CASHIER_TRANSACTION_PENDING: "GET_CASHIER_TRANSACTION_PENDING",
  LOADING_GET_CASHIER_TRANSACTION_PENDING:
    "LOADING_GET_CASHIER_TRANSACTION_PENDING",
};

export const CASHIER_DELIVERY_TRANSACTION = {
  LOADING_GET_CASHIER_DELIVERY_TRANSACTION:
    "LOADING_GET_CASHIER_DELIVERY_TRANSACTION",
  LOADING_POST_CASHIER_DELIVERY_TRANSACTION:
    "LOADING_POST_CASHIER_DELIVERY_TRANSACTION",
  GET_CASHIER_DELIVERY_TRANSACTION: "GET_CASHIER_DELIVERY_TRANSACTION",
};

export const CASHIER_DELIVERY_BATCH = {
  LOADING_GET_CASHIER_DELIVERY_BATCH: "LOADING_GET_CASHIER_DELIVERY_BATCH",
  LOADING_POST_CASHIER_DELIVERY_BATCH: "LOADING_POST_CASHIER_DELIVERY_BATCH",
  GET_CASHIER_DELIVERY_BATCH: "GET_CASHIER_DELIVERY_BATCH",
};

export const CASHIER_DELIVERY_POINT = {
  LOADING_GET_CASHIER_DELIVERY_POINT: "LOADING_GET_CASHIER_DELIVERY_POINT",
  LOADING_POST_CASHIER_DELIVERY_POINT: "LOADING_POST_CASHIER_DELIVERY_POINT",
  GET_CASHIER_DELIVERY_POINT: "GET_CASHIER_DELIVERY_POINT",
};

export const CASHIER_VENDOR = {
  LOADING_GET_CASHIER_VENDOR: "LOADING_GET_CASHIER_VENDOR",
  GET_CASHIER_VENDOR: "GET_CASHIER_VENDOR",
};
