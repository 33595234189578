import { VOUCHER } from "../../../constants/admin/masterdata/constants";

const initialState = {
	loadingGetVoucher: true,
	loadingPostVoucher: false,
	dataVoucher: [],
};

export const voucherReducer = (state = initialState, action) => {
	switch (action.type) {
		case VOUCHER.GET_DATA_VOUCHER_SUCCESS:
			return {
				...state,
				dataVoucher: action.data,
			};
		case VOUCHER.LOADING_DATA_VOUCHER_SUCCESS:
			return {
				...state,
				loadingGetVoucher: action.load,
			};
		case VOUCHER.LOADING_POST_VOUCHER_SUCCESS:
			return {
				...state,
				loadingPostVoucher: action.load,
			};
		default:
			return state;
	}
};
