import { REKAP_TRANSAKSI_VENDOR } from "../../constants/vendor/constant";

const initialState = {
  loadingRekapTransaksiVendor: true,
  dataRekapTransaksiVendor: [],

  loadingRekapTransaksiVendorChart: true,
  dataRekapTransaksiVendorChart: [],

  loadingRekapTransaksiVendorDetail: true,
  dataRekapTransaksiVendorDetail: [],
};

export const rekapTransaksiVendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case REKAP_TRANSAKSI_VENDOR.GET_REKAP_TRANSAKSI_VENDOR_DETAIL:
      return {
        ...state,
        dataRekapTransaksiVendorDetail: action.data,
      };

    case REKAP_TRANSAKSI_VENDOR.LOADING_GET_REKAP_TRANSAKSI_VENDOR_DETAIL:
      return {
        ...state,
        loadingRekapTransaksiVendorDetail: action.load,
      };

    case REKAP_TRANSAKSI_VENDOR.GET_REKAP_TRANSAKSI_VENDOR:
      return {
        ...state,
        dataRekapTransaksiVendor: action.data,
      };

    case REKAP_TRANSAKSI_VENDOR.LOADING_GET_REKAP_TRANSAKSI_VENDOR:
      return {
        ...state,
        loadingRekapTransaksiVendor: action.load,
      };

    case REKAP_TRANSAKSI_VENDOR.GET_REKAP_TRANSAKSI_VENDOR_CHART:
      return {
        ...state,
        dataRekapTransaksiVendorChart: action.data,
      };

    case REKAP_TRANSAKSI_VENDOR.LOADING_GET_REKAP_TRANSAKSI_VENDOR_CHART:
      return {
        ...state,
        loadingRekapTransaksiVendorChart: action.load,
      };

    default:
      return state;
  }
};
