import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import notificationData from "assets/data/notification.data.json";
// import { getToken } from "../../configs/firebase";
import Flex from "components/shared-components/Flex";
import axios from "axios";
import { showToken, onMessageListener } from "configs/FirebaseConfig";

import { env } from "configs/EnvironmentConfig";

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

const getNotificationBody = (list) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item
          className="list-clickable"
          style={{ background: item.color }}
        >
          <Flex alignItems="center">
            <div className="pr-3"></div>
            <div className="mr-3">
              <span className="font-weight-bold text-dark">{item.title} </span>
              <span className="text-gray-light">{item.content}</span>
            </div>
            {/* <small className="ml-auto">{item.created_at}</small> */}
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isTokenFound, setTokenFound] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };
  onMessageListener()
    .then((payload) => {
      getData();
    })
    .catch((err) => console.log("failed: ", err));

  const getData = () => {
    axios
      .get(env.API + "/notification/notification?offset=0&limit=25", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        data.forEach((d) => {
          if (d.read_at == null) {
            d["color"] = "white";
          } else {
            d["color"] = "#EEEEEE";
          }
        });
        setData(data);
      })
      .catch((e) => {
        setData([]);
      });
  };

  useEffect(() => {
    // setError(true);

    // if (!data.length) {
    showToken(setTokenFound);
    getData();
    // }
  }, []);

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        {/* <Button
          className="text-primary"
          type="link"
          onClick={() => setData([])}
          size="small"
        >
          Clear{" "}
        </Button> */}
      </div>
      <div className="nav-notification-body">{getNotificationBody(data)}</div>
      {data.length > 0 ? <div className="nav-notification-footer"></div> : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={data.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
