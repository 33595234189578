import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { DEALER_PREFIX_PATH } from "configs/AppConfig";
import ErrorOne from "views/auth-views/errors/error-page-1";

export const DealerViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${DEALER_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />

        <Route
          path={`${DEALER_PREFIX_PATH}/riwayat-pembayaran`}
          component={lazy(() => import(`./riwayat-pembayaran`))}
        />

        <Route
          path={`${DEALER_PREFIX_PATH}/edit-profile`}
          component={lazy(() => import(`./edit-profile`))}
        />
        <Redirect
          from={`${DEALER_PREFIX_PATH}`}
          to={`${DEALER_PREFIX_PATH}/home`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(DealerViews);
