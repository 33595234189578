import { CASHIER_DELIVERY_POINT } from "../../constants/kasir/constant";

const initialState = {
  loadingGetCashierDeliveryPoint: true,
  dataGetCashierDeliveryPoint: [],
};

export const deliveryPointCashierReducer = (state = initialState, action) => {
  switch (action.type) {
    case CASHIER_DELIVERY_POINT.GET_CASHIER_DELIVERY_POINT:
      return {
        ...state,
        dataGetCashierDeliveryPoint: action.data,
      };
    case CASHIER_DELIVERY_POINT.LOADING_GET_CASHIER_DELIVERY_POINT:
      return {
        ...state,
        loadingGetCashierDeliveryPoint: action.load,
      };
    default:
      return state;
  }
};
