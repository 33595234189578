import { DELIVERY_KITCHEN } from "../../constants/kitchen/constant.js";

const initialState = {
  loadingGetKitchenDelivery: true,
  loadingPostKitchenDelivery: false,
  dataGetKitchenDelivery: [],
};

export const deliveryKitchenReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_KITCHEN.GET_DELIVERY_KITCHEN:
      return {
        ...state,
        dataGetKitchenDelivery: action.data,
      };
    case DELIVERY_KITCHEN.LOADING_GET_DELIVERY_KITCHEN:
      return {
        ...state,
        loadingGetKitchenDelivery: action.load,
      };
    case DELIVERY_KITCHEN.LOADING_POST_DELIVERY_KITCHEN:
      return {
        ...state,
        loadingPostKitchenDelivery: action.load,
      };
    default:
      return state;
  }
};
