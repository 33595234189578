import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
  CoffeeOutlined,
  AppstoreOutlined,
  CarOutlined,
  ShopOutlined,
  DesktopOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { KASIR_PREFIX_PATH, KITCHEN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "delivery",
    path: `${KITCHEN_PREFIX_PATH}/delivery`,
    title: "Delivery",
    icon: CarOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
