import React, { useState, useEffect } from "react";
import {
  Menu,
  Dropdown,
  Badge,
  Avatar,
  List,
  Button,
  Skeleton,
  Spin,
} from "antd";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import notificationData from "assets/data/notification.data.json";
// import { getToken } from "../../configs/firebase";
import Flex from "components/shared-components/Flex";
import axios from "axios";
import { showToken, onMessageListener } from "configs/FirebaseConfig";

import { env } from "configs/EnvironmentConfig";
import { handleGet, handlePost } from "redux/actions/baseAction";

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isTokenFound, setTokenFound] = useState(false);
  const [countNotif, setCountNotif] = useState(0);
  const [loadingPost, setLoadingPost] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (countNotif > 0) {
      let dataId = [];
      data.map((res, i) => {
        dataId.push(res.id);
      });
      setLoadingPost(true);
      handlePost(
        "notification/notification/read",
        { id: dataId },
        (res, status) => {
          getData();
          setLoadingPost(false);
          setCountNotif(0);
        },
        false
      );
    }
  };

  const handlePostNotif = (id) => {
    handlePost(
      "notification/notification/read",
      { id: [id] },
      (res, status) => {
        getData();
        setLoadingPost(false);
      },
      false
    );
  };
  const getNotificationBody = (list) => {
    return list.length > 0 ? (
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            style={{ background: item.color }}
            className={`list-clickable ${
              item.color === "#EEEEEE" ? "notAllowed" : ""
            }`}
          >
            <Skeleton loading={loading} active>
              <Flex alignItems="center">
                <div className="pr-3"></div>
                <div
                  className="mr-3"
                  onClick={() => {
                    item.color !== "#EEEEEE" &&
                      handlePostNotif(item.id, item.type);
                  }}
                >
                  <span className="font-weight-bold text-dark">
                    {item.title}{" "}
                  </span>
                  <span className="text-gray-light">{item.content}</span>
                </div>
                {/* <small className="ml-auto">{item.created_at}</small> */}
              </Flex>
            </Skeleton>
          </List.Item>
        )}
      />
    ) : (
      <div className="empty-notification">
        <img
          src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
          alt="empty"
        />
        <p className="mt-3">You have viewed all notifications</p>
      </div>
    );
  };
  onMessageListener()
    .then((payload) => {
      getData();
    })
    .catch((err) => console.log("failed: ", err));

  const getData = () => {
    setLoading(true);
    handleGet(`notification/notification?offset=0&limit=25`, (res, status) => {
      let data = res.data;
      const newData = [];
      data.forEach((d) => {
        if (d.read_at == null) {
          d["color"] = "white";
          newData.push(d);
        } else {
          d["color"] = "#EEEEEE";
        }
      });
      // setOffset(offset);
      setData(data);
      setCountNotif(newData.length);
      setLoading(false);

      // let data = res.data;
      // const newData = [];
      // data.forEach((d) => {
      //   if (d.read_at == null) {
      //     d["color"] = "white";
      //     newData.push(d);
      //   } else {
      //     d["color"] = "#EEEEEE";
      //   }
      // });
      // setData(data);
      // setCountNotif(newData.length);
      // setLoading(false);
    });
  };

  useEffect(() => {
    showToken(setTokenFound);
    getData();
  }, []);

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
      </div>
      <Spin spinning={loadingPost}>
        <div className="nav-notification-body">{getNotificationBody(data)}</div>
      </Spin>
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={countNotif}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
