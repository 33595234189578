import { Types } from "../../actions/kasir/product";

const initialState = {
  loading: true,
  datum: [],
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        datum: action.data,
      };
    case Types.LOADING_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: action.payload.items,
      };
    default:
      return state;
  }
};
