import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import { env } from "./EnvironmentConfig";

var FirebaseConfig = null;
const env_conf = process.env.REACT_APP_ENV;
var vapidKey = "";

if (env_conf == "development") {
  FirebaseConfig = {
    apiKey: "AIzaSyAZwZI-3TcVQhD2S2YLWFT06nTeicSPnTg",
    authDomain: "kopkar-tmmin.firebaseapp.com",
    projectId: "kopkar-tmmin",
    storageBucket: "kopkar-tmmin.appspot.com",
    messagingSenderId: "305663343352",
    appId: "1:305663343352:web:eb0b972bc64b6d6f44190d",
    measurementId: "G-7L36THV33S",
  };
  vapidKey =
    "BIkmpLfaqUV6pryWHPt_588qLnBbwGUXTZQh8-7Lq1SzcsjtycqS8vDmTF6Hx8xOV31DmIDTzHnXYtOtMUcP5mg";
} else {
  FirebaseConfig = {
    apiKey: "AIzaSyA_bW35ZW4bYRpMm8oh0SDyLRVkoOfK_wc",
    authDomain: "tmmin-kopkar.firebaseapp.com",
    projectId: "tmmin-kopkar",
    storageBucket: "tmmin-kopkar.appspot.com",
    messagingSenderId: "461869388269",
    appId: "1:461869388269:web:8d546d272d0ed733a557e4",
    measurementId: "G-F076E3SEQZ",
  };
  vapidKey =
    "BAmX3qnIJRiqupWZvhmfkyZ1kD7ul9IWCQ9K1K6MRRKU0sTLrZVW7bIGlMbGnB0V8gAvBj7g2GHxqM9lwWUh1BI";
}
// const FirebaseConfig = {
//   apiKey: "AIzaSyAZwZI-3TcVQhD2S2YLWFT06nTeicSPnTg",
//   authDomain: "kopkar-tmmin.firebaseapp.com",
//   projectId: "kopkar-tmmin",
//   storageBucket: "kopkar-tmmin.appspot.com",
//   messagingSenderId: "305663343352",
//   appId: "1:305663343352:web:eb0b972bc64b6d6f44190d",
//   measurementId: "G-7L36THV33S",
// };

// Initialize Firebase
const app = initializeApp(FirebaseConfig);

const messaging = getMessaging(app);

export const showToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey: vapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);
        axios
          .post(
            env.API + "/notification/subscriber",
            { type: "WEB", token: currentToken },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });

        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// export default FirebaseConfig;
