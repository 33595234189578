import { CASHIER_VENDOR } from "../../constants/kasir/constant";

const initialState = {
  loadingGetCashierVendor: true,
  dataGetCashierVendor: [],
};

export const vendorCashierReducer = (state = initialState, action) => {
  switch (action.type) {
    case CASHIER_VENDOR.GET_CASHIER_VENDOR:
      return {
        ...state,
        dataGetCashierVendor: action.data,
      };
    case CASHIER_VENDOR.LOADING_GET_CASHIER_VENDOR:
      return {
        ...state,
        loadingGetCashierVendor: action.load,
      };
    default:
      return state;
  }
};
