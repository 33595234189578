import {
  DASHBOARD_VENDOR,
  STOCK_VENDOR,
} from "../../constants/vendor/constant";

const initialState = {
  loadingStockVendor: true,
  dataStockVendor: [],

  loadingDashboardVendor: true,
  dataDashboardVendor: [],
};

export const stockVendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_VENDOR.GET_STOCK_VENDOR:
      return {
        ...state,
        dataStockVendor: action.data,
      };

    case STOCK_VENDOR.LOADING_GET_STOCK_VENDOR:
      return {
        ...state,
        loadingStockVendor: action.load,
      };
    case DASHBOARD_VENDOR.GET_DASHBOARD_VENDOR:
      return {
        ...state,
        dataDashboardVendor: action.data,
      };

    case DASHBOARD_VENDOR.LOADING_GET_DASHBOARD_VENDOR:
      return {
        ...state,
        loadingDashboardVendor: action.load,
      };

    default:
      return state;
  }
};
