import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import navigationCafeConfig from "configs/NavigationAdminCafeConfig";
import navigationDanaProyekConfig, {
  routeConfigDanaProyek,
} from "configs/NavigationAdminDanaProyekConfig";
export const AdminViews = () => {
  const [stnk, setStnk] = useState(false);
  const [root, setRoot] = useState(false);
  const getData = () => {
    axios
      .get(env.API + "/admin/auth/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const data = res.data.data.role.permissions;
        data.forEach((d) => {
          if (d === "MANAGE_STNK") {
            setStnk(true);
          }else{
            if(d === "ROUTE"){
              setRoot(true)
              // localStorage.setItem('root', 'true');
            }
          }
        });
      })
      .catch((e) => {
        // localStorage.removeItem("token");
        // window.location.href = "/auth/login";
      });
  };
  useEffect(() => {
    // setError(true);

    // if (!data.length) {
    //  showToken(setTokenFound);
    getData();
    // let newArr = [
    //   {
    //     key: "data-vendor/detail",
    //     path: `${ADMIN_PREFIX_PATH}/data_vendor/:id`,
    //   },
    // ];

    // newArr.map((res) => {
    //   navigationCafeConfig.push(res);
    // });
    // }
  }, []);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      {stnk == true && (
        <Switch>
          <Route
            path={`${ADMIN_PREFIX_PATH}/pengajuan-stnk`}
            component={lazy(() => import(`./pengajuan-stnk`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-vendor`}
            component={lazy(() => import(`./master-vendor`))}
          />
          <Redirect
            from={`${ADMIN_PREFIX_PATH}`}
            to={`${ADMIN_PREFIX_PATH}/pengajuan-stnk`}
          />
        </Switch>
      )}
      {root == true && (
        <Switch>
          <Route
            path={`${ADMIN_PREFIX_PATH}/user`}
            component={lazy(() => import(`./root/user`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/koperasi`}
            component={lazy(() => import(`./root/koperasi`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/role`}
            component={lazy(() => import(`./root/role`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/add-article`}
            component={lazy(() => import(`./root/add-article`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/daftar-article`}
            component={lazy(() => import(`./root/daftar-article`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/article_detail/:id`}
            component={lazy(() => import(`./root/daftar-article/detail`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/role_detail/:id`}
            component={lazy(() => import(`./root/role/detail`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/user_detail/:id`}
            component={lazy(() => import(`./root/user/detail`))}
          /> 
          <Route
            path={`${ADMIN_PREFIX_PATH}/koperasi_detail/:id`}
            component={lazy(() => import(`./root/koperasi/detail`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/formuser`}
            component={lazy(() => import(`./root/user/component/FormUser`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/formrole`}
            component={lazy(() => import(`./root/role/component/FormRole`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/formkoperasi`}
            component={lazy(() => import(`./root/koperasi/component/FormKoperasi`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/permission`}
            component={lazy(() => import(`./root/permission`))}
          />
          <Redirect
            from={`${ADMIN_PREFIX_PATH}`}
            to={`${ADMIN_PREFIX_PATH}/user`}
          />
        </Switch>
      )}
     {stnk == false && root == false && (
        <Switch>
          <Route
            path={`${ADMIN_PREFIX_PATH}/home`}
            component={lazy(() => import(`./home`))}
          />

          <Route
            path={`${ADMIN_PREFIX_PATH}/bukti-transfer`}
            component={lazy(() => import(`./bukti-transfer`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/rekap-transaksi`}
            component={lazy(() => import(`./rekap-transaksi`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/download-csv`}
            component={lazy(() => import(`./download-csv`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/riwayat-pembayaran`}
            component={lazy(() => import(`./riwayat-pembayaran`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/add-kendaraan`}
            component={lazy(() => import(`./add-kendaraan`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/list-dealer`}
            component={lazy(() => import(`./list-dealer`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/verifikasi-user`}
            component={lazy(() => import(`./verif-user`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-karyawan`}
            component={lazy(() => import(`./master-karyawan`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/koperasi`}
            component={lazy(() => import(`./koperasi`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-bank`}
            component={lazy(() => import(`./master-bank`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-barang`}
            component={lazy(() => import(`./master-barang`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-kasir`}
            component={lazy(() => import(`./master-kasir`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-voucher`}
            component={lazy(() => import(`./master-voucher`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master_voucher/:id`}
            component={lazy(() => import(`./master-voucher/detail`))}
          />

          <Route
            path={`${ADMIN_PREFIX_PATH}/master-lokasi`}
            component={lazy(() => import(`./master-lokasi`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/lokasi/:id`}
            component={lazy(() => import(`./titik-pengantaran`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-delivery`}
            component={lazy(() => import(`./master-delivery`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-delivery-fee`}
            component={lazy(() => import(`./master-delivery-fee`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/point/:location`}
            component={lazy(() => import(`./delivery-fee`))}
          />

          <Route
            path={`${ADMIN_PREFIX_PATH}/pengajuan-pinjaman`}
            component={lazy(() => import(`./pengajuan-pinjaman`))}
          />

          <Route
            path={`${ADMIN_PREFIX_PATH}/settlement`}
            component={lazy(() => import(`./settlement`))}
          />
          {navigationCafeConfig.map((res, i) => {
            return (
              <Route
                key={i}
                path={res.path}
                component={lazy(() => import(`./cafe/${res.key}`))}
              />
            );
          })}
          <Route
            path={`${ADMIN_PREFIX_PATH}/cafe/data_vendor/:id`}
            component={lazy(() => import(`./cafe/data-vendor/detail`))}
          />

          <Route
            path={`${ADMIN_PREFIX_PATH}/cafe/rekap_transaksi/:id`}
            component={lazy(() => import(`./cafe/rekap-transaksi/detail`))}
          />

          {routeConfigDanaProyek.map((res, i) => {
            return (
              <Route
                key={i}
                path={`${ADMIN_PREFIX_PATH}/${res.path}`}
                component={lazy(() => import(`./${res.folder}`))}
              />
            );
          })}
          {/* <Route
            path={`${ADMIN_PREFIX_PATH}/dana-proyek/detail/dashboard/:id`}
            component={lazy(() => import(`./dana-proyek/dashboard/detail`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/dana-proyek/detail/pengembalian-dana/:id`}
            component={lazy(() =>
              import(`./dana-proyek/pengembalian-dana/detail`)
            )}
          /> */}

          {/* <Route
            path={`${ADMIN_PREFIX_PATH}/dana-proyek/detail/dashboard/:id`}
            component={lazy(() => import(`./dana-proyek/dashboard/detail`))}
          /> */}

          <Route
            path={`${ADMIN_PREFIX_PATH}/edit-profile`}
            component={lazy(() => import(`./edit-profile`))}
          />

          <Redirect
            from={`${ADMIN_PREFIX_PATH}`}
            to={`${ADMIN_PREFIX_PATH}/home`}
          />
        </Switch>
      )}
    </Suspense>
  );
};

export default React.memo(AdminViews);
