import { ITEM } from "../../../constants/admin/cafe/contants";

const initialState = {
  loadingGetItem: true,
  loadingPostItem: false,
  dataItem: [],
};

export const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEM.GET_DATA_ITEM_SUCCESS:
      return {
        ...state,
        dataItem: action.data,
      };
    case ITEM.LOADING_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loadingGetItem: action.load,
      };
    case ITEM.LOADING_POST_ITEM_SUCCESS:
      return {
        ...state,
        loadingPostItem: action.load,
      };
    default:
      return state;
  }
};
