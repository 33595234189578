import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import ErrorOne from "views/auth-views/errors/error-page-1";
import moment from "moment";
import { message } from "antd";

export const AppViews = () => {
  const STATUS = {
    STARTED: "Started",
    STOPPED: "Stopped",
  };

  const [status, setStatus] = useState(STATUS.STARTED);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(
    () => {
      let h = Number(moment().format("H"));
      let m = Number(moment().format("m"));
      let s = Number(moment().format("s"));
      if (h === 23 || h === 24 || h === 0 || h === 1) {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // 1000
  );

  useEffect(() => {
    let h = Number(moment().format("H"));
    let m = Number(moment().format("m"));
    let s = Number(moment().format("s"));
    // if (h === 23 || h === 24 || h === 0 || h === 1) {
    //   setStatus(STATUS.STOPPED);
    //   message.info("Mohon Maaf Sistem Dalam Keadaan Maintenance").then(() => {
    //     message.info("Anda Bisa Mencoba Kembali Setelah Jam 1").then(() => {
    //       localStorage.removeItem("token");
    //       window.location.reload();
    //     });
    //   });
    // } else {
    //   setStatus(STATUS.STARTED);
    // }
  }, [status]);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/riwayat-bulanan`}
          component={lazy(() => import(`./riwayat-bulanan`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/angsuran`}
          component={lazy(() => import(`./angsuran`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/spsw`}
          component={lazy(() => import(`./spsw`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/add-kendaraan`}
          component={lazy(() => import(`./add-kendaraan`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/list-kendaraan`}
          component={lazy(() => import(`./list-kendaraan`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pengajuan-pinjaman`}
          component={lazy(() => import(`./pengajuan-pinjaman`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/edit-profile`}
          component={lazy(() => import(`./edit-profile`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
