import { CASHIER_ITEM } from "../../constants/kasir/constant";

const initialState = {
  loadingGetItemCashier: true,
  loadingStoreItemCashier: false,
  dataGetItemCashier: [],
};

export const itemCashierReducer = (state = initialState, action) => {
  switch (action.type) {
    case CASHIER_ITEM.GET_ITEM_CASHIER:
      return {
        ...state,
        dataGetItemCashier: action.data,
      };
    case CASHIER_ITEM.LOADING_GET_ITEM_CASHIER:
      return {
        ...state,
        loadingGetItemCashier: action.load,
      };
    case CASHIER_ITEM.LOADING_STORE_ITEM_CASHIER:
      return {
        ...state,
        loadingStoreItemCashier: action.load,
      };
    default:
      return state;
  }
};
