import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import {
  Menu,
  Grid,
  Dropdown,
  Card,
  Divider,
  Avatar,
  Row,
  Col,
  Tag,
  Modal,
  Form,
  Input,
  Space,
  Button,
} from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationKasirConfig";
import navigationAllConfig from "configs/NavigationAdminAllConfig";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";

import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import {
  UserOutlined,
  PoweroffOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { handleGet, handlePost } from "redux/actions/baseAction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setClosing } from "redux/actions/kasir/transaction.action";
import { handleEmpty, rulesGeneral } from "helpers/general_helper";
import ButtonFormHelper from "helpers/button_form_helper";
import { isMobile, isAndroid, isTablet } from "react-device-detect";

require("moment-countdown");

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  console.log("################", isTablet);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [stnk, setStnk] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loadingClosing, setLoadingClosing] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [passEdit, setPassEdit] = useState(false);
  const [l, setl] = useState("");
  const [statusClosing, setStatusClosing] = useState(
    localStorage.statusClosing
  );
  const { closeCashier } = useSelector(
    (state) => state.transactionCashierReducer
  );
  const [dataProfile, setDataProfile] = useState(null);
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const getData = () => {
    axios
      .get(env.API + "/admin/auth/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const data = res.data.data.role.permissions;
        data.forEach((d) => {
          if (d == "MANAGE_STNK") {
            setStnk(true);
          }
        });
      })
      .catch((e) => {});
  };
  useEffect(() => {
    getData();
    handleProfile();
  }, []);

  const handleProfile = () => {
    handleGet("pos/pos/cashier/profile", (res) => {
      setDataProfile(res);
      form.setFieldsValue({ name: res.name });
    });
  };

  const handleClosing = () => {
    setLoadingClosing(true);
    let status = localStorage.statusClosing;
    if (status === "CLOSE") {
      localStorage.setItem("timeClosing", moment().format("YYYY-MM-DD H:mm"));
      dispatch(setClosing(false));
    } else {
      localStorage.removeItem("timeClosing");
      dispatch(setClosing(true));
    }

    status = status === "OPEN" ? "CLOSE" : "OPEN";

    handlePost(
      "pos/pos/cashier/location/status",
      { status: status },
      (datum, isTrue) => {
        let newStatus = localStorage.setItem("statusClosing", status);
        setStatusClosing(newStatus);
        setLoadingClosing(false);
        setVisible(false);
        setIsSubmit(false);
      },
      false
    );
  };

  let durationStorage = moment().countdown(localStorage.timeClosing);
  let hoursStorage = durationStorage.hours;
  let minutesStorage = durationStorage.minutes;

  let durasi = `${minutesStorage} Menit`;
  if (Number(hoursStorage) > 0) {
    durasi = `${hoursStorage} Jam ${minutesStorage} Menit`;
  }

  const handleSubmitProfile = (val) => {};

  return (
    <>
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? "hide-group-title" : ""}
      >
        {navigationConfig.map((menu) => {
          let isDisabled = false;
          if (
            menu.key === "transaksi" &&
            localStorage.statusClosing === "CLOSE"
          ) {
            isDisabled = true;
          }
          if (menu.key === "closing") {
            Object.assign(menu, {
              title: closeCashier ? "Kasir Tutup" : "Kasir Buka",
            });
          }
          return menu.submenu.length > 0 ? (
            <Menu.ItemGroup key={menu.key} title={menu.title}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={subMenuFirst.title}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>{subMenuSecond.title}</span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{subMenuFirst.title}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item
              key={menu.key}
              disabled={isDisabled}
              onClick={() => {
                if (menu.key === "closing") {
                  setVisible(!visible);
                }
              }}
            >
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span style={{ fontWeight: "bold" }}>&nbsp;{menu?.title}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          );
        })}

        <Menu.ItemGroup
          onClick={(e) => {
            setVisible(!visible);
          }}
          style={{
            marginLeft: "32px",
            marginTop: "20px",
            bottom: "0",
            cursor: "context-menu",
          }}
        >
          <Dropdown
            placement="topRight"
            onClick={(e) => {
              setVisible(!visible);
            }}
            onVisibleChange={(e) => {
              if (isSetting) {
                setVisible(false);
              } else {
              }
              setTimeout(() => {
                setVisible(false);
              }, 200);
            }}
            visible={(visible && !isSetting) || isSubmit}
            overlayStyle={{ width: "30%" }}
            overlay={
              <Card
                style={{ padding: "20px" }}
                title={
                  <div>
                    <Row>
                      <Col md={4}>
                        <Avatar
                          style={{
                            color: "white",
                            background: "#363A4E",
                          }}
                          shape="circle"
                        >
                          <strong>
                            {dataProfile ? dataProfile.name[0] : "X"}
                          </strong>
                        </Avatar>
                      </Col>
                      <Col style={{ marginLeft: "10px" }}>
                        <strong>{dataProfile ? dataProfile.name : ""}</strong>
                        <br />
                        <span style={{ color: "#2C2D30" }}>
                          {dataProfile ? dataProfile.location.name : ""}
                        </span>
                      </Col>
                      <Col md={24} style={{ marginTop: "10px" }}>
                        <Row align="space-between">
                          <Col>
                            <Tag
                              style={{
                                backgroundColor:
                                  localStorage.statusClosing === "OPEN"
                                    ? "#E8F4EE"
                                    : "white",
                                color:
                                  localStorage.statusClosing === "OPEN"
                                    ? "#04990A"
                                    : "red",
                                fontWeight: "bold",
                              }}
                            >
                              Kasir{" "}
                              {localStorage.statusClosing === "OPEN"
                                ? "Buka"
                                : "Tutup"}
                            </Tag>
                          </Col>
                          <p
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {localStorage.statusClosing === "OPEN" && durasi}
                          </p>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                }
              >
                <Divider />
                <div
                  style={{
                    color:
                      localStorage.statusClosing === "OPEN" ? "red" : "green",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsSubmit(true);
                    setVisible(true);
                    handleClosing();
                  }}
                >
                  <PoweroffOutlined />{" "}
                  {localStorage.statusClosing === "OPEN" ? "Tutup" : "Buka"}{" "}
                  Kasir{" "}
                  {loadingClosing && (
                    <span style={{ float: "right", color: "black" }}>
                      Mohon Tunggu ...
                    </span>
                  )}
                </div>
                <br />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setIsSetting(true);
                  }}
                >
                  <SettingOutlined /> Pengaturan
                </div>
              </Card>
            }
            trigger={["click"]}
          >
            <UserOutlined
              style={{ color: "transparent", cursor: "context-menu" }}
            />
          </Dropdown>
        </Menu.ItemGroup>
      </Menu>

      <Modal
        centered
        title={`Pengaturan`}
        visible={isSetting}
        closable={true}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
        onCancel={(e) => {
          setIsSetting(false);
          setVisible(false);
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmitProfile}
          preserve={false}
          onChange={(e) => {}}
        >
          <Form.Item
            hasFeedback
            placeholder="Masukan nama kasir"
            label="Nama"
            name="name"
            rules={rulesGeneral()}
          >
            <Input />
          </Form.Item>
          <Form.Item
            hasFeedback
            tooltip={
              <>
                minimal 8 karakter <br />
                maksimal 30 karakter <br />
                minimal 1 huruf besar <br />
                minimal 1 huruf kecil <br />
                minimal 1 angka <br />
                minimal 1 spesial karakter <br />
              </>
            }
            name="password"
            label="Password Baru"
            rules={[
              {
                pattern: new RegExp(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
                ),
                message:
                  "format salah, klik ikon tanda tanya untuk melihat format",
              },
            ]}
          >
            <Input.Password
              placeholder="Kosongkan Apabila Tidak Akan Diubah"
              onChange={(e) => {
                setl(e.target.value);
                setPassEdit(handleEmpty(e.target.value));
              }}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            tooltip={
              <>
                minimal 8 karakter <br />
                maksimal 30 karakter <br />
                minimal 1 huruf besar <br />
                minimal 1 huruf kecil <br />
                minimal 1 angka <br />
                minimal 1 spesial karakter <br />
              </>
            }
            name="password_confirmation"
            label="Konfirmasi Password Baru"
            rules={[
              {
                required: passEdit,
                message: "Tidak Boleh Kosong",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password Tidak Sama"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Kosongkan Apabila Tidak Akan Diubah" />
          </Form.Item>
          <Divider />
          <Row justify="end" gutter={4}>
            <Space>
              <Button
                size="small"
                htmlType="button"
                style={{
                  border: "1px solid #3699FF",
                  color: "#3699FF",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setIsSetting(false);
                  setVisible(false);
                }}
              >
                Batal
              </Button>
              <Button
                size="small"
                htmlType="submit"
                style={{
                  backgroundColor: "#3699FF",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Simpan Perubahan
              </Button>
            </Space>
          </Row>
          {/* <ButtonFormHelper
            callback={(e) => {
              form.resetFields();
            }}
            // loading={loadingPostCashier}
          /> */}
        </Form>
      </Modal>
    </>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{menu.title}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={subMenuFirst.title}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{subMenuSecond.title}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{subMenuFirst.title}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{menu?.title}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
