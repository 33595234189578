import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import { productReducer } from "./kasir/product";
import { itemCashierReducer } from "./kasir/itemCashier.reducer";
import { stockCashierReducer } from "./kasir/stockCashier.reducer";
import { salesCashierReducer } from "./kasir/salesCashier.reducer";
import { vendorReducer } from "./admin/cafe/vendor.reducer";
import { itemReducer } from "./admin/cafe/item.reducer";
import { kitchenReducer } from "./admin/kitchen/kitchen.reducer";
import { voucherReducer } from "./admin/masterdata/voucher.reducer";
import { bankReducer } from "./admin/masterdata/bank.reducer";
import { cashierReducer } from "./admin/masterdata/cashier.reducer";
import { locationReducer } from "./admin/masterdata/location.reducer";
import { locationPointReducer } from "./admin/masterdata/point.reducer";
import { dealerReducer } from "./admin/masterdata/dealer.reducer";
import { karyawanReducer } from "./hrd/karyawan/karyawan.reducer";
import { verifikasiUserReducer } from "./admin/masterdata/verifikasiUser.reducer";
import { rekapTransaksiReducer } from "./admin/rekapTransaksi.reducer";
import { pencairanDanaVendorReducer } from "./admin/cafe/pencairanDanaVendor.reducer";
import { rekapTransaksiAdminCafeReducer } from "./admin/cafe/rekapTransaksiAdminCafe.reducer";
import { locationVendorReducer } from "./vendor/locationVendor.reducer";
import { itemVendorReducer } from "./vendor/itemVendor.reducer";
import { pencairanVendorReducer } from "./vendor/pencairanVendor.reducer";
import { stockVendorReducer } from "./vendor/stockVendor.reducer";
import { rekapTransaksiVendorReducer } from "./vendor/rekapTransaksiVendor.reducer";
import { transactionCashierReducer } from "./kasir/transaction.reducer";
import { deliveryTransactionCashierReducer } from "./kasir/deliveryTransaction.reducer";
import { deliveryBatchCashierReducer } from "./kasir/deliveryBatch.reducer";
import { deliveryPointCashierReducer } from "./kasir/deliveryPoint.reducer";
import { vendorCashierReducer } from "./kasir/vendorCashier.reducer";
import { deliveryKitchenReducer } from "./kitchen/deliveryKitchen.reducer";
import { siteReducer } from "./site.reducers";

const reducers = combineReducers({
  vendorCashierReducer,
  rekapTransaksiVendorReducer,
  voucherReducer,
  kitchenReducer,
  deliveryKitchenReducer,
  rekapTransaksiAdminCafeReducer,
  pencairanDanaVendorReducer,
  pencairanVendorReducer,
  deliveryPointCashierReducer,
  deliveryBatchCashierReducer,
  deliveryTransactionCashierReducer,
  stockVendorReducer,
  transactionCashierReducer,
  itemVendorReducer,
  locationVendorReducer,
  itemCashierReducer,
  stockCashierReducer,
  salesCashierReducer,
  rekapTransaksiReducer,
  itemReducer,
  vendorReducer,
  bankReducer,
  cashierReducer,
  locationReducer,
  locationPointReducer,
  dealerReducer,
  karyawanReducer,
  verifikasiUserReducer,
  siteReducer,
  products: productReducer,
  theme: Theme,
  auth: Auth,
});

export default reducers;
