import { ITEM_VENDOR } from "../../constants/vendor/constant";

const initialState = {
  loadingItemVendor: true,
  loadingCreateItemVendor: false,
  dataItemVendor: [],
  dataItemPostVendor: [],
  loadingItemPostVendor: true,
};

export const itemVendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEM_VENDOR.GET_ITEM_POST_VENDOR:
      return {
        ...state,
        dataItemPostVendor: action.data,
      };
    case ITEM_VENDOR.GET_ITEM_VENDOR:
      return {
        ...state,
        dataItemVendor: action.data,
      };
    case ITEM_VENDOR.LOADING_GET_ITEM_VENDOR:
      return {
        ...state,
        loadingItemVendor: action.load,
      };
    case ITEM_VENDOR.LOADING_CREATE_ITEM_VENDOR:
      return {
        ...state,
        loadingCreateItemVendor: action.load,
      };
    case ITEM_VENDOR.LOADING_ITEM_POST_VENDOR:
      return {
        ...state,
        loadingItemPostVendor: action.load,
      };
    default:
      return state;
  }
};
