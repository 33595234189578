import { SITE } from "redux/constants/Site";
const initState = {
  connectionError: false,
};

export const siteReducer = (state = initState, action) => {
  switch (action.type) {
    case SITE.CONNECTION_ERROR:
      return {
        ...state,
        connectionError: action.load,
      };
    default:
      return state;
  }
};
