import { REKAP_TRANSAKSI_ADMIN_CAFE } from "../../../constants/admin/cafe/contants";

const initialState = {
  loadingGetRekapTransaksiAdminCafe: true,
  dataRekapTransaksiAdminCafe: [],

  loadingDetailGetRekapTransaksiAdminCafe: true,
  dataDetailRekapTransaksiAdminCafe: [],
  loadingPostRekapTransaksiAdminCafe: false,
};

export const rekapTransaksiAdminCafeReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case REKAP_TRANSAKSI_ADMIN_CAFE.GET_DATA_DETAIL_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
      return {
        ...state,
        dataDetailRekapTransaksiAdminCafe: action.data,
      };
    case REKAP_TRANSAKSI_ADMIN_CAFE.LOADING_DATA_DETAIL_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
      return {
        ...state,
        loadingDetailGetRekapTransaksiAdminCafe: action.load,
      };

    case REKAP_TRANSAKSI_ADMIN_CAFE.GET_DATA_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
      return {
        ...state,
        dataRekapTransaksiAdminCafe: action.data,
      };
    case REKAP_TRANSAKSI_ADMIN_CAFE.LOADING_DATA_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
      return {
        ...state,
        loadingGetRekapTransaksiAdminCafe: action.load,
      };
    case REKAP_TRANSAKSI_ADMIN_CAFE.LOADING_POST_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
      return {
        ...state,
        loadingPostRekapTransaksiAdminCafe: action.load,
      };
    default:
      return state;
  }
};
