import { LOCATION_VENDOR } from "../../constants/vendor/constant";

const initialState = {
  loadingLocationVendor: true,
  dataLocationVendor: [],
};

export const locationVendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_VENDOR.GET_LOCATION_VENDOR:
      return {
        ...state,
        dataLocationVendor: action.data,
      };
    case LOCATION_VENDOR.LOADING_GET_LOCATION_VENDOR:
      return {
        ...state,
        loadingLocationVendor: action.load,
      };
    default:
      return state;
  }
};
