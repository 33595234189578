export const VENDOR = {
  GET_DATA_VENDOR_SUCCESS: "GET_DATA_VENDOR_SUCCESS",
  GET_ALL_DATA_VENDOR_SUCCESS: "GET_ALL_DATA_VENDOR_SUCCESS",
  LOADING_DATA_VENDOR_SUCCESS: "LOADING_DATA_VENDOR_SUCCESS",
  LOADING_POST_VENDOR_SUCCESS: "LOADING_POST_VENDOR_SUCCESS",
  DETAIL_DATA_VENDOR_SUCCESS: "DETAIL_DATA_VENDOR_SUCCESS",
  GET_STATS_DATA_VENDOR_SUCCESS: "GET_STATS_DATA_VENDOR_SUCCESS",
  LOADING_STATS_DATA_VENDOR_SUCCESS: "LOADING_STATS_DATA_VENDOR_SUCCESS",
};

export const ITEM = {
  GET_DATA_ITEM_SUCCESS: "GET_DATA_ITEM_SUCCESS",
  LOADING_DATA_ITEM_SUCCESS: "LOADING_DATA_ITEM_SUCCESS",
  LOADING_POST_ITEM_SUCCESS: "LOADING_POST_ITEM_SUCCESS",
};

export const PENCAIRAN_DANA_VENDOR_ADMIN = {
  GET_DATA_PENCAIRAN_DANA_SUCCESS: "GET_DATA_PENCAIRAN_DANA_SUCCESS",
  LOADING_DATA_PENCAIRAN_DANA_SUCCESS: "LOADING_DATA_PENCAIRAN_DANA_SUCCESS",
  LOADING_POST_PENCAIRAN_DANA_SUCCESS: "LOADING_POST_PENCAIRAN_DANA_SUCCESS",
};

export const REKAP_TRANSAKSI_ADMIN_CAFE = {
  GET_DATA_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
    "GET_DATA_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS",
  LOADING_DATA_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
    "LOADING_DATA_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS",
  LOADING_POST_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
    "LOADING_POST_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS",
  GET_DATA_DETAIL_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
    "GET_DATA_DETAIL_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS",
  LOADING_DATA_DETAIL_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS:
    "LOADING_DATA_DETAIL_REKAP_TRANSAKSI_ADMIN_CAFE_SUCCESS",
};

export const STORAGE_REKAP_TRANSAKSI_ADMIN = {
  locationRekapTransaksi: "locationRekapTransaksi",
  vendorRekapTransaksi: "vendorRekapTransaksi",
  monthRekapTransaksi: "monthRekapTransaksi",
  startDateRekapTransaksi: "startDateRekapTransaksi",
  endDateRekapTransaksi: "endDateRekapTransaksi",
  detailRekapTransaksi: "detailRekapTransaksi",
};
