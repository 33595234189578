import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "./custom/Carousel.css";
import "@fontsource/inter"
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import { DSN_SENTRY } from "constants/ApiConstant";

// Sentry.init({
//   dsn: DSN_SENTRY,
//   integrations: [new Integrations.BrowserTracing({ tracingOrigins: ["*"] })],
//   tracesSampleRate: 1.0, // Set to a lower value (e.g., 0.1) for sample rate
//   environment: process.env.REACT_APP_ENV,
// });

ReactDOM.render(
  // <Sentry.ErrorBoundary fallback={"error Front End"}>
  <App />,
  // </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.start();
// serviceWorker.firebase();

serviceWorker.unregister();
// serviceWorker.register();
