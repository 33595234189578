import { KITCHEN } from "../../../constants/admin/masterdata/constants";

const initialState = {
  loadingGetKitchen: true,
  dataKitchen: [],
};

export const kitchenReducer = (state = initialState, action) => {
  switch (action.type) {
    case KITCHEN.GET_DATA_KITCHEN_SUCCESS:
      return {
        ...state,
        dataKitchen: action.data,
      };
    case KITCHEN.LOADING_DATA_KITCHEN_SUCCESS:
      return {
        ...state,
        loadingGetKitchen: action.load,
      };
    default:
      return state;
  }
};
