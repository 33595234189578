import { VENDOR } from "../../../constants/admin/cafe/contants";

const initialState = {
  loading: true,
  loadingPost: false,
  data: [],
  detail: [],
  allData: [],
  loadingStatsVendor: true,
  statsDataVendor: [],
};

export const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case VENDOR.GET_STATS_DATA_VENDOR_SUCCESS:
      return {
        ...state,
        statsDataVendor: action.data,
      };
    case VENDOR.GET_ALL_DATA_VENDOR_SUCCESS:
      return {
        ...state,
        allData: action.data,
      };
    case VENDOR.DETAIL_DATA_VENDOR_SUCCESS:
      return {
        ...state,
        detail: action.data,
      };
    case VENDOR.GET_DATA_VENDOR_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case VENDOR.LOADING_DATA_VENDOR_SUCCESS:
      return {
        ...state,
        loading: action.load,
      };
    case VENDOR.LOADING_POST_VENDOR_SUCCESS:
      return {
        ...state,
        loadingPost: action.load,
      };
    case VENDOR.LOADING_STATS_DATA_VENDOR_SUCCESS:
      return {
        ...state,
        loadingStatsVendor: action.load,
      };
    default:
      return state;
  }
};
