import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { MANAGER_PREFIX_PATH } from "configs/AppConfig";
import ErrorOne from "views/auth-views/errors/error-page-1";

export const ManagerViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${MANAGER_PREFIX_PATH}/approval-pinjaman`}
          component={lazy(() => import(`./approval-pinjaman`))}
        />
        <Route
          path={`${MANAGER_PREFIX_PATH}/pelunasan`}
          component={lazy(() => import(`./pelunasan`))}
        />
        <Route
          path={`${MANAGER_PREFIX_PATH}/pengaturan-budget`}
          component={lazy(() => import(`./pengaturan-budget`))}
        />
        <Route
          path={`${MANAGER_PREFIX_PATH}/pinjaman-barang`}
          component={lazy(() => import(`./pinjaman-barang`))}
        />
        <Route
          path={`${MANAGER_PREFIX_PATH}/pinjaman-stnk`}
          component={lazy(() => import(`./pinjaman-stnk`))}
        />

        <Route
          path={`${MANAGER_PREFIX_PATH}/edit-profile`}
          component={lazy(() => import(`./edit-profile`))}
        />
        <Redirect
          from={`${MANAGER_PREFIX_PATH}`}
          to={`${MANAGER_PREFIX_PATH}/approval-pinjaman`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(ManagerViews);
