import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { DEALER_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${DEALER_PREFIX_PATH}/home`,
    title: "Dashboard",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "riwayat-pembayaran",
    path: `${DEALER_PREFIX_PATH}/riwayat-pembayaran`,
    title: "Riwayat PO",
    icon: RetweetOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
