import { CASHIER_DELIVERY_TRANSACTION } from "../../constants/kasir/constant";

const initialState = {
  loadingGetCashierDeliveryTransaction: true,
  loadingPostCashierDeliveryTransaction: false,
  dataGetCashierDeliveryTransaction: [],
};

export const deliveryTransactionCashierReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CASHIER_DELIVERY_TRANSACTION.GET_CASHIER_DELIVERY_TRANSACTION:
      return {
        ...state,
        dataGetCashierDeliveryTransaction: action.data,
      };
    case CASHIER_DELIVERY_TRANSACTION.LOADING_GET_CASHIER_DELIVERY_TRANSACTION:
      return {
        ...state,
        loadingGetCashierDeliveryTransaction: action.load,
      };
    case CASHIER_DELIVERY_TRANSACTION.LOADING_POST_CASHIER_DELIVERY_TRANSACTION:
      return {
        ...state,
        loadingPostCashierDeliveryTransaction: action.load,
      };
    default:
      return state;
  }
};
