import { CASHIER_TRANSACTION } from "../../constants/kasir/constant";

const initialState = {
  loadingStoreCashierTransaction: false,
  dataGetStoreCashierTransaction: [],

  loadinGetCashierTransactionPendig: false,
  closeCashier: true,
  dataGetCashierTransactionPending: [],
};

export const transactionCashierReducer = (state = initialState, action) => {
  switch (action.type) {
    case CASHIER_TRANSACTION.GET_CASHIER_TRANSACTION:
      return {
        ...state,
        dataGetStoreCashierTransaction: action.data,
      };
    case CASHIER_TRANSACTION.LOADING_STORE_CASHIER_TRANSACTION:
      return {
        ...state,
        loadingStoreCashierTransaction: action.load,
      };
    case CASHIER_TRANSACTION.GET_CASHIER_TRANSACTION_PENDING:
      return {
        ...state,
        dataGetCashierTransactionPending: action.data,
      };
    case CASHIER_TRANSACTION.LOADING_GET_CASHIER_TRANSACTION_PENDING:
      return {
        ...state,
        loadinGetCashierTransactionPendig: action.load,
      };
    case CASHIER_TRANSACTION.CLOSE_CASHIER_TRANSACTION:
      return {
        ...state,
        closeCashier: action.load,
      };
    default:
      return state;
  }
};
