import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "pengajuan_stnk",
    path: `${ADMIN_PREFIX_PATH}/pengajuan-stnk`,
    title: "Pengajuan STNK",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-vendor",
    path: `${ADMIN_PREFIX_PATH}/master-vendor`,
    title: "Data Vendor",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationStnkConfig = [...dashBoardNavTree];

export default navigationStnkConfig;
