export const DASHBOARD_VENDOR = {
  GET_DASHBOARD_VENDOR: "GET_DASHBOARD_VENDOR",
  LOADING_GET_DASHBOARD_VENDOR: "LOADING_GET_DASHBOARD_VENDOR",
};

export const LOCATION_VENDOR = {
  GET_LOCATION_VENDOR: "GET_LOCATION_VENDOR",
  LOADING_GET_LOCATION_VENDOR: "LOADING_GET_LOCATION_VENDOR",
};

export const STOCK_VENDOR = {
  GET_STOCK_VENDOR: "GET_STOCK_VENDOR",
  LOADING_GET_STOCK_VENDOR: "LOADING_GET_STOCK_VENDOR",
};

export const ITEM_VENDOR = {
  GET_ITEM_POST_VENDOR: "GET_ITEM_POST_VENDOR",
  LOADING_ITEM_POST_VENDOR: "LOADING_ITEM_POST_VENDOR",
  GET_ITEM_VENDOR: "GET_ITEM_VENDOR",
  LOADING_GET_ITEM_VENDOR: "LOADING_GET_ITEM_VENDOR",
  LOADING_CREATE_ITEM_VENDOR: "LOADING_CREATE_ITEM_VENDOR",
};

export const PENCAIRAN_VENDOR = {
  GET_PENCAIRAN_VENDOR: "GET_PENCAIRAN_VENDOR",
  LOADING_GET_PENCAIRAN_VENDOR: "LOADING_GET_PENCAIRAN_VENDOR",
};

export const REKAP_TRANSAKSI_VENDOR = {
  GET_REKAP_TRANSAKSI_VENDOR: "GET_REKAP_TRANSAKSI_VENDOR",
  LOADING_GET_REKAP_TRANSAKSI_VENDOR: "LOADING_GET_REKAP_TRANSAKSI_VENDOR",
  GET_REKAP_TRANSAKSI_VENDOR_CHART: "GET_REKAP_TRANSAKSI_VENDOR_CHART",
  LOADING_GET_REKAP_TRANSAKSI_VENDOR_CHART:
    "LOADING_GET_REKAP_TRANSAKSI_VENDOR_CHART",
  GET_REKAP_TRANSAKSI_VENDOR_DETAIL: "GET_REKAP_TRANSAKSI_VENDOR_DETAIL",
  LOADING_GET_REKAP_TRANSAKSI_VENDOR_DETAIL:
    "LOADING_GET_REKAP_TRANSAKSI_VENDOR_DETAIL",
};
