export const KITCHEN = {
  GET_DATA_KITCHEN_SUCCESS: "GET_DATA_KITCHEN_SUCCESS",
  LOADING_DATA_KITCHEN_SUCCESS: "LOADING_DATA_KITCHEN_SUCCESS",
};

export const LOCATIONS = {
  GET_DATA_LOCATION_SUCCESS: "GET_DATA_LOCATION_SUCCESS",
  LOADING_DATA_LOCATION_SUCCESS: "LOADING_DATA_LOCATION_SUCCESS",
  LOADING_POST_LOCATION_SUCCESS: "LOADING_POST_LOCATION_SUCCESS",

  GET_DATA_LOCATION_POINT_SUCCESS: "GET_DATA_LOCATION_POINT_SUCCESS",
  LOADING_DATA_LOCATION_POINT_SUCCESS: "LOADING_DATA_LOCATION_POINT_SUCCESS",
  LOADING_POST_LOCATION_POINT_SUCCESS: "LOADING_POST_LOCATION_POINT_SUCCESS",

  GET_DATA_LOCATION_FEE_SUCCESS: "GET_DATA_LOCATION_FEE_SUCCESS",
  LOADING_DATA_LOCATION_FEE_SUCCESS: "LOADING_DATA_LOCATION_FEE_SUCCESS",
  LOADING_POST_LOCATION_FEE_SUCCESS: "LOADING_POST_LOCATION_FEE_SUCCESS",
};

export const BANKS = {
  GET_DATA_BANKS_SUCCESS: "GET_DATA_BANKS_SUCCESS",
  LOADING_DATA_BANKS_SUCCESS: "LOADING_DATA_BANKS_SUCCESS",
};

export const CASHIER = {
  GET_DATA_CASHIER_SUCCESS: "GET_DATA_CASHIER_SUCCESS",
  LOADING_DATA_CASHIER_SUCCESS: "LOADING_DATA_CASHIER_SUCCESS",
  LOADING_POST_CASHIER_SUCCESS: "LOADING_POST_CASHIER_SUCCESS",
};

export const VOUCHER = {
  GET_DATA_VOUCHER_SUCCESS: "GET_DATA_VOUCHER_SUCCESS",
  LOADING_DATA_VOUCHER_SUCCESS: "LOADING_DATA_VOUCHER_SUCCESS",
  LOADING_POST_VOUCHER_SUCCESS: "LOADING_POST_VOUCHER_SUCCESS",
};

export const DEALER = {
  GET_DATA_DEALER_SUCCESS: "GET_DATA_DEALER_SUCCESS",
  LOADING_DATA_DEALER_SUCCESS: "LOADING_DATA_DEALER_SUCCESS",
  LOADING_POST_DEALER_SUCCESS: "LOADING_POST_DEALER_SUCCESS",
};

export const VERIFIKASI_USER = {
  GET_DATA_VERIFIKASI_USER_SUCCESS: "GET_DATA_VERIFIKASI_USER_SUCCESS",
  LOADING_DATA_VERIFIKASI_USER_SUCCESS: "LOADING_DATA_VERIFIKASI_USER_SUCCESS",
  LOADING_POST_VERIFIKASI_USER_SUCCESS: "LOADING_POST_VERIFIKASI_USER_SUCCESS",
};

export const REKAP_TRANSAKSI = {
  GET_DATA_REKAP_TRANSAKSI_USER_SUCCESS:
    "GET_DATA_REKAP_TRANSAKSI_USER_SUCCESS",
  LOADING_DATA_REKAP_TRANSAKSI_USER_SUCCESS:
    "LOADING_DATA_REKAP_TRANSAKSI_USER_SUCCESS",
  DOWNLOAD_DANA_POTONGAN: "DOWNLOAD_DANA_POTONGAN",
  LOADING_DOWNLOAD_DANA_POTONGAN: "LOADING_DOWNLOAD_DANA_POTONGAN",
};
